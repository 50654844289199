import React, { useReducer } from 'react'
import SocketContext from './socketContext'
import socketReducer from './socketReducer'

import io from 'socket.io-client'

import { SOCKET_CONNECTED, NEW_MESSAGE_FROM_SERVER, NEW_MESSAGE_FROM_MYSELF } from '../types'

const SocketState = props => {
  const initialState = {}

  const [state, dispatch] = useReducer(socketReducer, initialState)

  // Socket Connection
  const connect = e => {
    console.log('geldi')
    console.log(io().disconnect())
    io()
    dispatch({ type: SOCKET_CONNECTED, payload: e })
    io().on('hey', e => {
      console.log('iki')
      io().emit('hey-2', { socketid: e, userid: state.user._id })
    })
  }

  // Message from other peer
  const recievedMessageFromServer = e => {
    dispatch({ type: NEW_MESSAGE_FROM_SERVER, payload: e })
  }

  // Message from client himself
  const updateMessages = e => {
    dispatch({ type: NEW_MESSAGE_FROM_MYSELF, payload: e })
  }

  return (
    <SocketContext.Provider
      value={{
        connect,
        recievedMessageFromServer,
        updateMessages
      }}
    >
      {props.children}
    </SocketContext.Provider>
  )
}

export default SocketState
