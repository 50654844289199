import React, { useContext } from 'react'
import LikeCard from '../layout/LikeCard'

import LikeContext from '../../context/like/likeContext'
import AuthContext from '../../context/auth/authContext'

export default function RightMenuBody() {
  const likeContext = useContext(LikeContext)
  const { likes } = likeContext

  const authContext = useContext(AuthContext)
  const { loading } = authContext

  return (
    <div className={likes.length === 0 ? 'right-menu-body-empty' : 'right-menu-body'}>
      {loading ? (
        <div className='spinner-container'>
          <i className='fas fa-spinner fa-spin spinner-3'></i>
        </div>
      ) : (
        <div className='menu-body-container'>
          {likes.length === 0 ? (
            <div className='section-message section-message-add'>
              <div className='section-message-text'>
                You haven't liked anyone yet.
                <br />
                Search and like.
              </div>
            </div>
          ) : null}
          <div className='menu-body-cards'>
            {likes.map(like => (
              <LikeCard key={like._id} like={like} />
            ))}
          </div>
          <div className='menu-body-plus-area-content'></div>
        </div>
      )}
    </div>
  )
}
