import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import LikeContext from '../../context/like/likeContext'

LikeItem.propTypes = {
  like: PropTypes.object.isRequired,
}

export default function LikeItem({ like }) {
  const likeContext = useContext(LikeContext)
  const { deleteLike } = likeContext

  const {
    _id,
    likedUsername,
    likedInstagramFullName,
    isMatched,
  } = like

  const onDelete = () => {
    deleteLike(_id)
  }

  return (
    <div className="card">
      <img
        style={{
          float: 'left',
          height: '56px',
          width: '56px',
          borderRadius: '100px',
        }}
        src={require('../layout/icons-profile2.png')}
        alt=""
      ></img>
      <div style={{ float: 'left', paddingLeft: '10px' }}>
        {' '}
        <h3 className="text-left">{likedUsername + ' '} </h3>
        <p>{likedInstagramFullName}</p>
      </div>
      <p style={{ float: 'right' }}>
        {isMatched ? (
          <button className="btn btn-dark btn-sm">Send Message</button>
        ) : (
          ''
        )}
        <button className="btn btn-danger btn-sm" onClick={onDelete}>
          Remove
        </button>
      </p>
    </div>
  )
}
