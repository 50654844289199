import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Support() {
    useEffect(() => {
        window.scrollTo(0, 0)
        //eslint-disable-next-line
      }, [])
    return (
        <div>
            <nav
                id='navbar'
                style={{
                    width: '100%',
                }}>
                <Link to='/' className='header-logo-black'>
                    <img src='/logo-black.svg' alt='' />
                </Link>
            </nav>

            <div className='page support-page-main'>
                <h1>Rosa App Support</h1>
                <p>
                    At Rosa App, we're committed to providing you with the best possible experience
                    as you explore the world of Instagram-based dating. We're here to assist you
                    with any questions, concerns, or technical issues you may encounter along the
                    way.
                </p>
                <h2>Contact Us</h2>
                <p>
                    If you have any questions or feedback, don't hesitate to contact us. We're here
                    to help you with any issues or concerns you may have. Please reach out to us
                    through our email address{' '}
                    <Link
                        onClick={e => {
                            e.preventDefault()
                            window.location.href = 'mailto:info@userosa.com'
                        }}
                        to='#'>
                        info@userosa.com
                    </Link>
                </p>
                <h2>We Value Your Feedback</h2>
                <p>
                    Your feedback is essential to us as we strive to improve your experience on Rosa
                    App. If you have any suggestions, feature requests, or feedback about your
                    experience, please let us know. We genuinely appreciate your input and take it
                    into consideration as we continue to enhance our platform.
                </p>
                <p>
                    Thank you for choosing Rosa App as your Instagram-based dating destination.
                    We're excited to help you connect with like-minded individuals and make
                    meaningful connections.
                </p>
            </div>
        </div>
    )
}
