import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import ChatroomCard from './ChatroomCard'
import ChatRoomCardEmpty from './ChatRoomCardEmpty'

import ChatroomContext from '../../context/chatroom/chatroomContext'

export default function ChatroomsColumn() {
  const chatroomContext = useContext(ChatroomContext)
  const { chatRooms, currentChatRoom, loading } = chatroomContext

  const { reciever } = useParams()

  if (loading && chatRooms === undefined) {
    return (
      <div className='spinner-container'>
        <i className='fas fa-spinner fa-spin spinner-3'></i>
      </div>
    )
  }

  return (
    <div
      className={
        !chatRooms || chatRooms.length === 0 || chatRooms[0] === null
          ? 'chatrooms-empty'
          : 'chatrooms'
      }
    >
      {currentChatRoom &&
        !currentChatRoom.lastMessage &&
        chatRooms &&
        chatRooms.filter(e => {
          return e.mongoID === reciever
        }).length === 0 && <ChatRoomCardEmpty chatRoom={currentChatRoom} />}
      {chatRooms &&
        chatRooms.length > 0 &&
        chatRooms[0] &&
        chatRooms.map(e => <ChatroomCard chatRoom={e} key={e.mongoID} />)}
      {(!loading && chatRooms && chatRooms.length === 0) ||
        (chatRooms === undefined && (
          <div className='you-have-no-messages-container'>
            <div className='you-have-no-messages'>You have no messages yet.</div>
          </div>
        ))}
    </div>
  )
}
