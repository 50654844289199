export const GET_LIKES = 'GET_LIKES'
export const ADD_LIKE = 'ADD_LIKE'
export const DELETE_LIKE = 'DELETE_LIKE'
export const SET_CURRENT = 'SET_CURRENT'
export const CLEAR_CURRENT = 'CLEAR_CURRENT'
export const FILTER_LIKES = 'FILTER_LIKES'
export const CLEAR_LIKE_FILTER = 'CLEAR_LIKE_FILTER'
export const CLEAR_LIKES = 'CLEAR_LIKES'
export const LIKE_ERROR = 'LIKE_ERROR'
export const FILTER_MESSAGES = 'FILTER_MESSAGES'
export const CLEAR_MESSAGE_FILTER = 'CLEAR_MESSAGE_FILTER'
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const SEARCH_USER = 'SEARCH_USER'
export const SEARCH_ERROR = 'SEARCH_ERROR'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const SET_LOADING = 'SET_LOADING'
export const SET_MODAL = 'SET_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'
export const REFRESH_CREDITS = 'REFRESH_CREDITS'
export const GET_PLANS = 'GET_PLANS'
export const EMAIL_SEND_SUCCESS = 'EMAIL_SEND_SUCCESS'
export const EMAIL_SEND_FAIL = 'EMAIL_SEND_FAIL'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_FAIL = 'VERIFY_FAIL'
export const SEARCH_FOCUSED = 'SEARCH_FOCUSED'
export const VERIFY_USERNAME_SUCCESS = 'VERIFY_USERNAME_SUCCESS'
export const VERIFY_USERNAME_FAIL = 'VERIFY_USERNAME_FAIL'
export const SUCCESS = 'SUCCESS'
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'
export const CHATROOMS_LOADED = 'CHATROOMS_LOADED'
export const CHATROOMS_ERROR = 'CHATROOMS_ERROR'
export const SET_CURRENT_CHATROOM = 'SET_CURRENT_CHATROOM'
export const CLEAR_CURRENT_CHATROOM = 'CLEAR_CURRENT_CHATROOM'
export const MESSAGES_LOADED = 'MESSAGES_LOADED'
export const MESSAGES_ERROR = 'MESSAGES_ERROR'
export const CLEAR_CHATROOMS = 'CLEAR_CHATROOMS'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export const SET_LOADING_TRUE = 'SET_LOADING_TRUE'
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL'
export const NEW_MESSAGE_FROM_SERVER = 'NEW_MESSAGE_FROM_SERVER'
export const NEW_MESSAGE_FROM_MYSELF = 'NEW_MESSAGE_FROM_MYSELF'
export const MESSAGES_READ = 'MESSAGES_READ'
export const CARD_INFO = 'CARD_INFO'
export const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE'
export const SET_FLASH = 'SET_FLASH'
export const REMOVE_FLASH = 'REMOVE_FLASH'
export const RESET_FLASH = 'RESET_FLASH'
export const NEW_MATCH = 'NEW_MATCH'
export const NEW_MATCH_FALSE = 'NEW_MATCH_FALSE'
export const CHATROOMID_LOADED = 'CHATROOMID_LOADED'
export const READ_MESSAGES = 'READ_MESSAGES'
export const READ_MESSAGE_NOW = 'READ_MESSAGE_NOW'
export const EMPTY_NEW_MESSAGES = 'EMPTY_NEW_MESSAGES'
export const REGISTER_FALSEN = 'REGISTER_FALSEN'
export const INSTAGRAM_USER_NOT_FOUND = 'INSTAGRAM_USER_NOT_FOUND'
export const SET_INSTAGRAM_USER_NOT_FOUND = 'SET_INSTAGRAM_USER_NOT_FOUND'
