import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import AuthContext from '../../context/auth/authContext'
import FlashContext from '../../context/flash/flashContext'

import AuthNavbar from '../layout/AuthNavbar'
import Flash from '../layout/Flash'
import LoginFail from './LoginFail'

export default function Login(props) {
  const authContext = useContext(AuthContext)
  const flashContext = useContext(FlashContext)

  const {
    login,
    error,
    clearErrors,
    isAuthenticated,
    user,
    loginSuccess,
    loadUser,
  } = authContext
  const { flash, flashMessagesArray, setFlash, resetFlash } = flashContext

  const loginUsernameInput = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 950) {
      loginUsernameInput.current.blur()
    }
    if (typeof window !== 'undefined' && window.innerWidth > 950) {
      loginUsernameInput.current.focus()
    }
    return () => {
      resetFlash()
      clearErrors()
      setLoginError(false)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (loginSuccess) {
      loadUser()
    }
    //eslint-disable-next-line
  }, [loginSuccess])

  useEffect(() => {
    if (isAuthenticated) {
      resetFlash()
      clearErrors()
      setLoginError(false)
      props.history.push('/home')
    }
    // if (isAuthenticated && user && !user.isEmailVerified) {
    //   setLoginError(true)
    //   localStorage.removeItem('token')
    // }
    //eslint-disable-next-line
  }, [user])

  const [loginError, setLoginError] = useState(false)

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  })

  const { username, password } = formData

  const [loadingIconTrue, setLoadingIconTrue] = useState(false)

  useEffect(() => {
    if (error.length > 0) {
      setFlash({
        type: 'red',
        message: error[0],
        duration: 5000,
        id: uuidv4(),
      })
    }
    //eslint-disable-next-line
  }, [error])

  useEffect(() => {
    if (flashMessagesArray.length > 0) {
      setLoadingIconTrue(false)
    }
  }, [flashMessagesArray])

  //This is very clever. Whichever the inputs change, it'll take the name attribute and match it with the input value
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setLoadingIconTrue(true)
    if (username === '' || password === '') {
      setFlash({
        type: 'red',
        message: 'All fields must be filled.',
        duration: 5000,
        id: uuidv4(),
      })
    } else {
      console.log('here')
      login({
        username: username.toLowerCase(),
        password,
      })
    }
  }

  // useEffect(() => {
  //   if (loginError && user) {
  //     sendEmail({
  //       email: user.email,
  //       id: user._id,
  //     })
  //   }
  //   //eslint-disable-next-line
  // }, [loginError])

  return (
    <>
      {flash && <Flash />}
      <AuthNavbar />
      <section id="login-main-section">
        <div className="login-content">
          {loginError ? (
            <LoginFail />
          ) : (
            <>
              <h3 className="login-header">Log in to Rosa</h3>
              <form onSubmit={onSubmit} className="login-form" action="">
                <input
                  ref={loginUsernameInput}
                  className="input login-email"
                  type="text"
                  placeholder="Instagram username"
                  autoFocus={true}
                  name="username"
                  onChange={onChange}
                />
                <input
                  className="input login-password"
                  type="password"
                  placeholder="Rosa Password"
                  name="password"
                  onChange={onChange}
                />
                <button
                  onSubmit={onSubmit}
                  className={
                    loadingIconTrue
                      ? 'login-button-disabled'
                      : 'btn-success login-button'
                  }
                >
                  {loadingIconTrue ? (
                    <i className="fas fa-spinner fa-spin spinner-2"></i>
                  ) : (
                    ''
                  )}
                  {loadingIconTrue ? 'Please wait...' : 'LOGIN'}{' '}
                </button>
              </form>
              <div className="login-bundle">
                <a href="/forgot-password" className="forget-password">
                  Forgot password?
                </a>
                {'  ·  '}
                <Link to="/signup-one">Sign up for Rosa</Link>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  )
}
