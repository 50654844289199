import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Landing from './components/pages/Landing';
import Likes from './components/pages/Likes';
import Messages from './components/pages/Messages';
import Messages1 from './components/pages/Messages1';
import Matches from './components/pages/Matches';
import Credits from './components/pages/Credits';
import Profile from './components/pages/Profile';
import ChangeEmail from './components/pages/ChangeEmail';
import ChangePlan from './components/pages/ChangePlan';
import ChangePayment from './components/pages/ChangePayment';
import ChangePassword from './components/pages/ChangePassword';
import SignUpOne from './components/pages/SignUpOne';
import SignUpTwo from './components/pages/SignUpTwo';
import Login from './components/pages/Login';
import TermsAndConditions from './components/pages/TermsAndConditions';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Support from './components/pages/Support';
import CookiesPolicy from './components/pages/CookiesPolicy';
import ForgotPassword from './components/pages/ForgotPassword';
import Alerts from './components/layout/Alerts';
import PrivateRoute from './components/routing/PrivateRoute';
import Dashboard from './components/pages/Dashboard';
import LoginFail from './components/pages/LoginFail';
import EmailVerification from './components/pages/EmailVerification';

import LikeState from './context/like/LikeState';
import AuthState from './context/auth/AuthState';
import SocketState from './context/socket/SocketState';
import AlertState from './context/alert/AlertState';
import SearchState from './context/search/SearchState';
import ModalState from './context/modal/ModalState';
import FlashState from './context/flash/FlashState';
import ChatroomState from './context/chatroom/ChatroomState';

import setAuthToken from './utils/setAuthToken';
import './App.css';
import InstaConnect from './components/pages/InstaConnect';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  return (
    <SocketState>
      <AuthState>
        <LikeState>
          <AlertState>
            <FlashState>
              <ModalState>
                <SearchState>
                  <ChatroomState>
                    <Router>
                      <Fragment>
                        <Alerts />
                        <Switch>
                          <Route exact path="/" component={Landing} />
                          <PrivateRoute
                            exact
                            path="/home"
                            component={Dashboard}
                          />
                          <Route
                            exact
                            path="/terms-and-conditions"
                            component={TermsAndConditions}
                          />
                          <PrivateRoute
                            exact
                            path="/instagram-connection"
                            component={InstaConnect}
                          />
                          <Route
                            exact
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                          />
                          <Route
                            exact
                            path="/support"
                            component={Support}
                          />
                          <PrivateRoute
                            exact
                            path="/cookies-policy"
                            component={CookiesPolicy}
                          />
                          <PrivateRoute exact path="/likes" component={Likes} />
                          <PrivateRoute
                            exact
                            path="/messages"
                            component={Messages}
                          />
                          <PrivateRoute
                            exact
                            path="/matches"
                            component={Matches}
                          />
                          <PrivateRoute
                            exact
                            path="/credits"
                            component={Credits}
                          />
                          <PrivateRoute
                            exact
                            path="/profile"
                            component={Profile}
                          />
                          <PrivateRoute
                            exact
                            path="/change-email"
                            component={ChangeEmail}
                          />
                          <PrivateRoute
                            exact
                            path="/change-plan"
                            component={ChangePlan}
                          />
                          <PrivateRoute
                            exact
                            path="/change-payment"
                            component={ChangePayment}
                          />
                          <PrivateRoute
                            exact
                            path="/change-password"
                            component={ChangePassword}
                          />
                          <Route
                            exact
                            path="/api/verify/email/:token"
                            component={EmailVerification}
                          />
                          <PrivateRoute
                            exact
                            path="/messages/:sender-:reciever"
                            component={Messages1}
                          />
                          <Route
                            exact
                            path="/signup-one"
                            component={SignUpOne}
                          />
                          <Route
                            exact
                            path="/signup-two"
                            component={SignUpTwo}
                          />
                          <Route 
                            exact path="/login" 
                            component={Login}
                          />
                          <Route
                            exact
                            path="/login-fail"
                            component={LoginFail}
                          />
                          <PrivateRoute
                            exact
                            path="/forgot-password"
                            component={ForgotPassword}
                          />
                        </Switch>
                      </Fragment>
                    </Router>
                  </ChatroomState>
                </SearchState>
              </ModalState>
            </FlashState>
          </AlertState>
        </LikeState>
      </AuthState>
    </SocketState>
  );
};

export default App;
