import {
  SEARCH_USER,
  CLEAR_SEARCH,
  SEARCH_ERROR,
  SET_LOADING,
  INSTAGRAM_USER_NOT_FOUND,
  SET_INSTAGRAM_USER_NOT_FOUND,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case SEARCH_USER:
      return {
        ...state,
        loading: false,
        searchResults: action.payload,
      };
    case CLEAR_SEARCH:
    case SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        searchResults: [],
        instagramUserNotFound: false,
      };
    case INSTAGRAM_USER_NOT_FOUND:
      return {
        ...state,
        loading: false,
        searchResults: [],
        instagramUserNotFound: true,
      };
    case SET_INSTAGRAM_USER_NOT_FOUND:
      return {
        ...state,
        instagramUserNotFound: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
