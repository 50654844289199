import React, { useContext } from 'react';
import AuthContext from '../../context/auth/authContext';

export default function ProfilePic({ pageName }) {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  if (!user || !user.profilePic) {
    return <img src={require('./icons-profile2.png')} alt="Profile" />;
  }
  if (user && user.profilePic) {
    return (
      <img
        src={
          user.profilePic ? user.profilePic : require('./icons-profile2.png')
        }
        alt="Profile"
      />
    );
  }
}
