import React, { useContext, useEffect } from 'react'

import ChatroomContext from '../../context/chatroom/chatroomContext'
import AuthContext from '../../context/auth/authContext'

export default function RightMenuBodyMessages() {
  const chatroomContext = useContext(ChatroomContext)
  const { chatRooms, updateLastMessage, loading } = chatroomContext

  const authContext = useContext(AuthContext)
  const { newMessage } = authContext

  useEffect(() => {
    window.scrollTo(0, 0)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (newMessage && newMessage.length) {
      updateLastMessage({
        id: newMessage[0].chatroomID,
        date: Date.now(),
        from: newMessage[0].from,
        to: newMessage[0].to,
        read: false,
        text: newMessage[0].text
      })
    }
    //eslint-disable-next-line
  }, [newMessage])

  return (
    <div className='right-menu-body-messages'>
      {loading ? (
        <div className='spinner-container'>
          <i className='fas fa-spinner fa-spin spinner-3'></i>
        </div>
      ) : (
        <div className='menu-body-plus-area-content-3'>
          {chatRooms && chatRooms.length > 0 && chatRooms[0] !== null ? (
            <div className='not-have-a-message-selected'>
              <div className='not-have-a-message-selected-bold'>
                You don't have a message selected
              </div>
              <div className='not-have-a-message-selected-thin'>
                Choose one from your existing messages or start a new one from matches.
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  )
}
