import React, { useEffect, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import DashboardLeft from '../layout/DashboardLeft'
import RightMenuHead from '../layout/RightMenuHead'
import RightMenuBody from '../layout/RightMenuBody'
import VerifyUsername from '../layout/VerifyUsername'
import DashboardFooter from '../layout/DashboardFooter'
import Search from '../layout/Search'
import Modal from '../layout/Modal'
import Flash from '../layout/Flash'

import LikeContext from '../../context/like/likeContext'
import AuthContext from '../../context/auth/authContext'
import ModalContext from '../../context/modal/modalContext'
import FlashContext from '../../context/flash/flashContext'

export default function Dashboard() {
  const likeContext = useContext(LikeContext)
  const { likes, getLikes, newMatch, newMatchFalse } = likeContext

  const flashContext = useContext(FlashContext)
  const { flash, setFlash, resetFlash } = flashContext

  const authContext = useContext(AuthContext)
  const { loading, user, loadUser, clearSuccess, clearErrors } = authContext

  const modalContext = useContext(ModalContext)
  const { modal } = modalContext

  useEffect(() => {
    if (user === null) {
      loadUser()
    }
    getLikes()
    window.scrollTo(0, 0)
    return () => {
      resetFlash()
      clearSuccess()
      clearErrors()
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (newMatch) {
      setFlash({
        type: 'green',
        message: 'You have a new match!',
        duration: 5000,
        id: uuidv4()
      })
      newMatchFalse()
    }
    //eslint-disable-next-line
  }, [newMatch])

  // After user.isEmailVerified is true
  useEffect(() => {
    if (user && user.isEmailVerified) {
      setTimeout(() => {
        clearSuccess()
      }, 3000)
    }
    //eslint-disable-next-line
  }, [user])

  if (loading) {
    return <h1>Loading...</h1>
  }

  return (
    <>
      <div className='dashboard-body'>
        <div className='bg-left'></div>

        <div className='bg-right'></div>

        <div className='container'>
          {flash && <Flash />}
          <DashboardLeft pageName={'Likes'} />

          <div className='right-menu'>
            <RightMenuHead title={user.isUsernameVerified ? 'Likes' : 'Verify username'} />

            {user.isUsernameVerified ? (
              <>
                <Search
                  placeholder={
                    likes.length === 0
                      ? 'Start using Rosa here. Search for someone.'
                      : 'Search for more Instagram usernames'
                  }
                />
                <RightMenuBody />
              </>
            ) : (
              <VerifyUsername />
            )}
            <DashboardFooter pageName={'Likes'} />
          </div>
        </div>
      </div>
      {modal ? <Modal /> : ''}
    </>
  )
}
