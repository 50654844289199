import React, { useContext, useState } from 'react'
import PlanCard from './PlanCard'

import LikeContext from '../../context/like/likeContext'
import AuthContext from '../../context/auth/authContext'
import { useEffect } from 'react'

export default function RightMenuBodyCredits() {
  const likeContext = useContext(LikeContext)
  const { likes, getPlans, plans } = likeContext

  const authContext = useContext(AuthContext)
  const { credits, user } = authContext

  const [planss, setPlanss] = useState([])

  useEffect(() => {
    getPlans()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    setPlanss(plans)
  }, [plans])

  return (
    <>
      <div className='credits-section-content'>
        <div className='credits-head-content'>
          <div className='available-section'>
            <p className='available-credits'>
              <span className='bold-content'>Current Plan: </span>
              {user.plan}
            </p>
          </div>
          <div className='available-section'>
            <p className='available-credits'>
              <span className='bold-content'>Available credits: </span>
              {user.plan === 'Premium' ? 'Unlimited' : credits}
            </p>
          </div>
          <div className='used-section'>
            <p className='used-credits'>
              <span className='bold-content'>Used credits: </span>
              {likes.length}
            </p>
          </div>
          {user.plan !== 'Premium' ? (
            <div className='total-section'>
              <p className='total-credits'>
                <span className='bold-content'>Total credits: </span>
                {user.credits + likes.length}
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='plans-body-container'>
        <div className='plans'>
          {planss && planss.map(plan => <PlanCard key={plan._id} plan={plan} />)}
        </div>
      </div>
    </>
  )
}
