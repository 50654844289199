import React, { useContext } from 'react'

import AuthContext from '../../context/auth/authContext'

export default function LoginFail() {
  const authContext = useContext(AuthContext)
  const { user, sendEmail, emailSentCount } = authContext
  const { email, _id } = user

  const onClickHandler = () => {
    if (emailSentCount < 3) {
      sendEmail({
        email,
        id: _id
      })
    } else {
      alert('You reached email limit. Please check your email or contact support')
    }
  }

  return (
    <div>
      <i className='far fa-envelope-open login-fail-icon'></i>
      <p className='check-your-email'>To login, check your email</p>
      <p className='security-content'>
        For security, we've sent you an email to <span className='email-bold'>{user.email}</span>.
        <br />
        Simply click the link in the email and you'll be all set.
      </p>
      <div className='send-email-bundle'>
        <p className='send-email-bundle-1'>Didn't get an email? </p>
        <p onClick={onClickHandler} className='send-email-bundle-2'>
          Send it again.
        </p>
      </div>
    </div>
  )
}
