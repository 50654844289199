import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import AuthNavbar from '../layout/AuthNavbar'
import Flash from '../layout/Flash'

import FlashContext from '../../context/flash/flashContext'

export default function SignUp(props) {
  const flashContext = useContext(FlashContext)
  const { flash, setFlash, resetFlash } = flashContext

  const [user, setUser] = useState({
    username: '',
    email: '',
    password: ''
  })

  const { username } = user

  const signUpUsernameInput = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 950) {
      signUpUsernameInput.current.blur()
    }
    if (typeof window !== 'undefined' && window.innerWidth > 950) {
      signUpUsernameInput.current.focus()
    }
    if (sessionStorage.getItem('username')) {
      const sessionUsername = sessionStorage.getItem('username')
      setUser({ ...user, username: sessionUsername })
    }
    return () => {
      resetFlash()
    }
    //eslint-disable-next-line
  }, [])

  // This is very clever. Whichever the inputs change, it'll take the name attribute and match it with the input value
  const onChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const onSubmit = e => {
    e.preventDefault()
    if (username === '') {
      setFlash({
        type: 'red',
        message: 'Please enter your Instagram username.',
        duration: 5000,
        id: uuidv4()
      })
    } else {
      var myString = username
      while (myString.charAt(0) === '@') myString = myString.slice(1)
      sessionStorage.setItem('username', myString)
      props.history.push('/signup-two')
    }
  }

  return (
    <>
      {flash && <Flash />}
      <AuthNavbar />
      <section id='signup-main-section'>
        <div className='signup-content'>
          <p className='signup-header'>Step 1/2: Enter your Instagram username</p>
          <form onSubmit={onSubmit} className='login-form' action=''>
            <input
              ref={signUpUsernameInput}
              className='input signup-username'
              type='text'
              name='username'
              // placeholder='Your Instagram username'
              placeholder='This is your Instagram username'
              onChange={onChange}
              value={username}
            />
            <div className='signup-button-bundle'>
              <Link className='btn-blue back-button' to='/'>
                BACK
              </Link>
              <button onSubmit={onSubmit} className='btn success continue-button'>
                {' '}
                CONTINUE{' '}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}
