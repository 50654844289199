import React from 'react'
import Likes from '../likes/Likes'
import LikeFilter from '../likes/LikeFilter'

export default function Home() {
  return (
    <div>
      <LikeFilter />
      <div className='grid-2'>
        <Likes />
      </div>
    </div>
  )
}
