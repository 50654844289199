import React, { useEffect, useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import ModalContext from '../../context/modal/modalContext';
import LikeContext from '../../context/like/likeContext';
import SearchContext from '../../context/search/searchContext';
import AuthContext from '../../context/auth/authContext';
import FlashContext from '../../context/flash/flashContext';

export default function Modal(props) {
  const modalContext = useContext(ModalContext);
  const {
    modalHeader,
    modalMessage,
    modalButtonName,
    modalFunction,
    removeModal,
    id,
    likedUsername,
    likedInstagramFullName,
    likedInstagramProfilePic,
    modalCancelButton,
    modalFunctionButtonColor,
  } = modalContext;

  const likeContext = useContext(LikeContext);
  const { addLike, deleteLike, getLikes } = likeContext;

  const searchContext = useContext(SearchContext);
  const { clearSearch } = searchContext;

  const authContext = useContext(AuthContext);
  const { loadUser } = authContext;

  const flashContext = useContext(FlashContext);
  const { setFlash } = flashContext;

  const [loadingIconTrue, setLoadingIconTrue] = useState(false);

  //bunu sadece search-results-container render olduğunda yapacağız yoksa her yerde esc i dinler
  useEffect(() => {
    document.addEventListener('keyup', keypressHandler);
    return () => document.removeEventListener('keyup', keypressHandler);
    //eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   removeModal()
  //   //eslint-disable-next-line
  // }, [likes])

  const keypressHandler = (e) => {
    if (e.keyCode === 27) {
      removeModal();
    }
    if (e.keyCode === 13) {
      modalFunctionHandler();
    }
  };

  const modalRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', outsideClickHandler);
    return () => document.removeEventListener('mousedown', outsideClickHandler);
    //eslint-disable-next-line
  }, []);

  const outsideClickHandler = (e) => {
    if (!modalRef.current.contains(e.target)) {
      removeModal();
    }
  };

  const modalFunctionHandler = async (props) => {
    setLoadingIconTrue(true);
    if (modalFunction === 'deleteLike') {
      await deleteLike(id);
      removeModal();
      setFlash({
        type: 'blue',
        message: 'User has been deleted successfully.',
        duration: 3000,
        id: uuidv4(),
      });
    }
    if (modalFunction === 'addLike') {
      await addLike({
        likedUsername,
        likedInstagramID: '',
        likedInstagramFullName,
        likedInstagramProfilePic,
      });
      clearSearch();
      loadUser();
      getLikes();
      removeModal();
      setFlash({
        type: 'blue',
        message: `@${likedUsername} has been liked.`,
        duration: 3000,
        id: uuidv4(),
      });
    }
    if (modalFunction === 'changeEmail') {
      removeModal();
    }
    if (modalFunction === 'cancelPlan') {
      removeModal();
    }
    if (modalFunction === 'changePassword') {
      removeModal();
    }
    if (modalFunction === 'goToPlans') {
      removeModal();
      clearSearch();
    }
  };

  return (
    <div className="modal">
      <div ref={modalRef} className="modal-content">
        <div className="modal-container">
          <div className="modal-header">{modalHeader}</div>
          <div className="modal-message">{modalMessage}</div>
          <div className="modal-buttons">
            {modalCancelButton ? (
              <div onClick={removeModal} className="modal-button-1">
                Cancel
              </div>
            ) : (
              ''
            )}

            {modalFunction === 'goToPlans' ? (
              <Link
                to="/credits"
                onClick={modalFunctionHandler}
                className={
                  'modal-button-2 ' +
                  (loadingIconTrue ? 'modal-button-2-disabled ' : '') +
                  (modalFunctionButtonColor === 'green'
                    ? 'modal-button-2-green'
                    : '' || modalFunctionButtonColor === 'red'
                    ? 'modal-button-2-red'
                    : '' || modalFunctionButtonColor === 'blue'
                    ? 'modal-button-2-blue'
                    : '' || loadingIconTrue
                    ? 'modal-button-2-disabled'
                    : '')
                }
              >
                {loadingIconTrue ? (
                  <i className="fas fa-spinner fa-spin spinner-4"></i>
                ) : (
                  ''
                )}
                {loadingIconTrue ? 'Working...' : modalButtonName}
              </Link>
            ) : (
              <div
                onClick={modalFunctionHandler}
                className={
                  'modal-button-2 ' +
                  (loadingIconTrue ? 'modal-button-2-disabled ' : '') +
                  (modalFunctionButtonColor === 'green'
                    ? 'modal-button-2-green'
                    : '' || modalFunctionButtonColor === 'red'
                    ? 'modal-button-2-red'
                    : '' || modalFunctionButtonColor === 'blue'
                    ? 'modal-button-2-blue'
                    : '')
                }
              >
                {loadingIconTrue ? (
                  <i className="fas fa-spinner fa-spin spinner-4"></i>
                ) : (
                  ''
                )}
                {loadingIconTrue ? 'Working...' : modalButtonName}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
