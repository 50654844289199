import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import ChatroomContext from '../../context/chatroom/chatroomContext'

export default function RightMenuHead({ title }) {
  const chatroomContext = useContext(ChatroomContext)
  const { currentChatRoom } = chatroomContext

  return (
    <>
      <div className="right-menu-head-messages-2">
        {currentChatRoom ? (
          <>
            <Link to="/messages">
              <i className="fas fa-arrow-left left-button messages-back-icon"></i>
            </Link>
            <img
              className="selected-profile-pic"
              src={
                currentChatRoom.profilePic
                  ? require('./icons-profile2.png')
                  : '' || currentChatRoom.likedInstagramProfilePic
                  ? require('./icons-profile2.png')
                  : ''
              }
              alt=""
            />
            <div className="flex-left-info-column">
              {/* <div className='selected-fullname'>{currentChatRoom.likedInstagramFullName}</div> */}
              <div className="selected-username">
                {currentChatRoom.username
                  ? currentChatRoom.username
                  : '' || currentChatRoom.likedUsername
                  ? currentChatRoom.likedUsername
                  : ''}
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
