import React, { useReducer } from 'react';
import ChatroomContext from './chatroomContext';
import chatroomReducer from './chatroomReducer';
import setAuthToken from '../../utils/setAuthToken';
import axios from 'axios';

import {
  CHATROOMS_LOADED,
  CHATROOMS_ERROR,
  SET_CURRENT_CHATROOM,
  CLEAR_CURRENT_CHATROOM,
  MESSAGES_LOADED,
  MESSAGES_ERROR,
  CLEAR_CHATROOMS,
  CLEAR_MESSAGES,
  SET_LOADING_TRUE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
  UPDATE_LAST_MESSAGE,
  CHATROOMID_LOADED,
  READ_MESSAGES,
} from '../types';

const ChatroomState = (props) => {
  const initialState = {
    chatrooms: [],
    loading: true,
    error: null,
    currentChatRoom: null,
    messages: null,
    currentChatroomID: null,
    messageSent: false,
  };

  const [state, dispatch] = useReducer(chatroomReducer, initialState);

  //GET CHATROOMS
  const getChatrooms = async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      const res = await axios.get('/api/messages');
      dispatch({
        type: CHATROOMS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: CHATROOMS_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  //SEND MESSAGE
  const sendMessage = async (formData) => {
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('/api/messages/send', formData, config);
      dispatch({
        type: SEND_MESSAGE_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: SEND_MESSAGE_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  //GET ID OF A USERNAME
  const getUserID = async (username) => {
    try {
      const res = await axios.get(`/api/likes/${username}`);
      return res;
    } catch (err) {
      dispatch({
        type: SEND_MESSAGE_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  //CLEAR CHATROOMS
  const clearChatrooms = () => {
    dispatch({
      type: CLEAR_CHATROOMS,
    });
  };

  //CLEAR MESSAGES
  const clearMessages = () => {
    dispatch({
      type: CLEAR_MESSAGES,
    });
  };

  //SET LOADING TRUE
  const setLoadingTrue = () => {
    dispatch({
      type: SET_LOADING_TRUE,
    });
  };

  //SET CURRENT CHATROOM
  const setCurrentChatRoom = (prop) => {
    dispatch({
      type: SET_CURRENT_CHATROOM,
      payload: prop,
    });
    if (prop.lastMessage) {
      dispatch({
        type: CHATROOMID_LOADED,
        payload: prop.lastMessage.chatroomID,
      });
    }
  };

  //CLEAR CURRENT CHATROOM
  const clearCurrentChatRoom = (prop) => {
    dispatch({
      type: CLEAR_CURRENT_CHATROOM,
    });
  };

  //GET MESSAGES OF A CHATROOM
  const getMessages = async (prop) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      const res = await axios.get(`/api/messages/${prop}`);
      dispatch({
        type: MESSAGES_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: MESSAGES_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  //GET MESSAGES OF A CHATROOM
  const getMessagesAlternate = async (prop) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('/api/messages/chatrooms', prop, config);
      getMessages(res.data.chatRoomID._id);
      dispatch({
        type: CHATROOMID_LOADED,
        payload: res.data.chatRoomID._id,
      });
    } catch (err) {
      dispatch({
        type: MESSAGES_ERROR,
        payload: err.response.data.errors,
      });
    }
  };

  //MAKE MESSAGES READ
  const readMessages = async (prop) => {
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      await axios.post('/api/messages/', { chatroomID: prop }, config);
      dispatch({
        type: READ_MESSAGES,
        payload: prop,
      });
    } catch (err) {
      console.log(err.response.data.errors);
      // dispatch({
      //   type: MESSAGES_ERROR,
      //   payload: err.response.data.errors
      // })
    }
  };

  //MAKE MESSAGES READ ONLY IN UI
  const readMessagesOnlyUI = async (prop) => {
    dispatch({
      type: READ_MESSAGES,
      payload: prop,
    });
  };

  //SET CURRENT CHATROOM
  const updateLastMessage = (prop) => {
    dispatch({
      type: UPDATE_LAST_MESSAGE,
      payload: prop,
    });
  };

  return (
    <ChatroomContext.Provider
      value={{
        chatRooms: state.chatRooms,
        loading: state.loading,
        error: state.error,
        currentChatRoom: state.currentChatRoom,
        messages: state.messages,
        currentChatroomID: state.currentChatroomID,
        messageSent: state.messageSent,
        getChatrooms,
        clearChatrooms,
        setCurrentChatRoom,
        clearCurrentChatRoom,
        getMessages,
        clearMessages,
        setLoadingTrue,
        sendMessage,
        getUserID,
        readMessages,
        readMessagesOnlyUI,
        updateLastMessage,
        getMessagesAlternate,
      }}
    >
      {props.children}
    </ChatroomContext.Provider>
  );
};

export default ChatroomState;
