import { SET_FLASH, REMOVE_FLASH, RESET_FLASH } from '../types'

export default (state, action) => {
  switch (action.type) {
    case SET_FLASH:
      return {
        ...state,
        flash: true,
        flashMessagesArray: [action.payload, ...state.flashMessagesArray]
      }
    case REMOVE_FLASH:
      let trueOrFalse
      if (state.flashMessagesArray.length > 1) {
        trueOrFalse = true
      } else {
        trueOrFalse = false
      }
      return {
        ...state,
        flash: trueOrFalse,
        flashMessagesArray: state.flashMessagesArray.filter(e => {
          return e.id !== action.payload
        })
      }
    case RESET_FLASH:
      return {
        ...state,
        flash: false,
        flashMessagesArray: []
      }
    default:
      return state
  }
}
