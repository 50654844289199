import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import DashboardLeft from '../layout/DashboardLeft'
import RightMenuBodyPayment from '../layout/RightMenuBodyPayment'
import DashboardFooter from '../layout/DashboardFooter'
import Modal from '../layout/Modal'
import Login from '../pages/Login'

import AuthContext from '../../context/auth/authContext'
import ModalContext from '../../context/modal/modalContext'

export default function ChangePlan(props) {
  const authContext = useContext(AuthContext)
  const { loading, loadUser, isAuthenticated } = authContext

  const modalContext = useContext(ModalContext)
  const { modal } = modalContext

  useEffect(() => {
    loadUser()
    //eslint-disable-next-line
  }, [])

  if (loading) {
    return <h1>Loading...</h1>
  }

  if (!isAuthenticated) {
    return <Login />
  }

  return (
    <>
      <div>
        <div className='dashboard-body'>
          <div className='bg-left'></div>
          <div className='bg-right'></div>

          <div className='container'>
            <DashboardLeft pageName={'Profile'} />
            <div className='right-menu'>
              <div className='right-menu-head'>
                <div className='menu-head-container'>
                  {/* <Link to='/profile' className='menu-head-profile-pic'>
                    <img src={user.profilePic} alt='' />
                  </Link> */}

                  <div className='menu-head-header'>
                    <Link to='/profile'>
                      <i className='fas fa-arrow-left left-button'></i>
                    </Link>
                    Profile
                  </div>
                  <div className='menu-head-messages-icon'>
                    <Link to='/messages'>
                      <i className='far fa-envelope'></i>
                    </Link>
                  </div>
                </div>
              </div>
              <RightMenuBodyPayment />
            </div>
            <DashboardFooter pageName={'Profile'} />
          </div>
        </div>
      </div>
      {modal ? <Modal /> : ''}
    </>
  )
}
