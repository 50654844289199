import React, { useReducer } from 'react';
import SearchContext from './searchContext';
import searchReducer from './searchReducer';

import {
  SEARCH_USER,
  CLEAR_SEARCH,
  SET_LOADING,
  INSTAGRAM_USER_NOT_FOUND,
  SET_INSTAGRAM_USER_NOT_FOUND,
} from '../types';
import { generateRandomUserProfiles } from '../../utils/generator';

const SearchState = (props) => {
  const initialState = {
    searchResults: [],
    error: null,
    loading: null,
    instagramUserNotFound: false,
  };

  const [state, dispatch] = useReducer(searchReducer, initialState);

  //SEARCH FOR A USER
  const searchUser = async (searchItem) => {
    try {
      const payload = generateRandomUserProfiles(searchItem.searchItem, 200);
      dispatch({ type: SEARCH_USER, payload });
    } catch (err) {
      dispatch({ type: INSTAGRAM_USER_NOT_FOUND });
    }
  };

  //CLEAR SEARCH
  const clearSearch = () => {
    dispatch({ type: CLEAR_SEARCH });
  };

  //SET INSTAGRAM USER NOT FOUND
  const igUserNotFound = (e) => {
    dispatch({ type: SET_INSTAGRAM_USER_NOT_FOUND, e });
  };

  //SET LOADING
  const setLoading = () => {
    dispatch({ type: SET_LOADING });
  };

  return (
    <SearchContext.Provider
      value={{
        searchResults: state.searchResults,
        error: state.error,
        loading: state.loading,
        instagramUserNotFound: state.instagramUserNotFound,
        searchUser,
        clearSearch,
        setLoading,
        igUserNotFound,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchState;
