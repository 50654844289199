import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import AuthContext from '../../context/auth/authContext'

export default function RightMenuHead({ title }) {
  const authContext = useContext(AuthContext)
  const { user } = authContext

  return (
    <>
      <div
        className={
          title === 'Messages' ? 'right-menu-head-messages' : 'right-menu-head'
        }
      >
        <div className="menu-head-container">
          <Link to="/profile" className="menu-head-profile-pic">
            {user ? <img src={require('./icons-profile2.png')} alt="" /> : ''}
          </Link>

          <div className="menu-head-header">{title}</div>
          <div className="menu-head-messages-icon">
            <Link to="/messages">
              {title === 'Messages' ? (
                <i className="fas fa-envelope-open"></i>
              ) : (
                <i className="far fa-envelope"></i>
              )}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
