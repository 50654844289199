import React from 'react'
import { Link } from 'react-router-dom'

export default function ChatroomCardEmpty({ chatRoom }) {
  return (
    <Link to='#' className='chatroom-card-selected'>
      <div className='chatroom-card-container'>
        <div className='chatroom-card-flex-1'>
          <div className='flex-left'>
            <img className='chatroom-profile-pic' src={chatRoom.likedInstagramProfilePic} alt='' />

            <div className='flex-left-info-column'>
              <div className='chatroom-username'>@{chatRoom.likedUsername}</div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
