import React, { useContext } from 'react'

import FlashItem from './FlashItem'

import FlashContext from '../../context/flash/flashContext'

export default function Flash() {
  const flashContext = useContext(FlashContext)
  const { flashMessagesArray } = flashContext

  return (
    <div className='flash'>
      <div className='flash-konteyner'>
        {flashMessagesArray.map(e => {
          return <FlashItem flash={e} key={e.id} />
        })}
      </div>
    </div>
  )
}
