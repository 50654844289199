import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import AuthContext from '../../context/auth/authContext'
import ModalContext from '../../context/modal/modalContext'

export default function RightMenuBodyPlan(props) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const authContext = useContext(AuthContext)
  const { user } = authContext

  const modalContext = useContext(ModalContext)
  const { setModal } = modalContext

  const date = new Date(user.signUpDate)
  const month = date.getMonth()
  const monthName = monthNames[month]
  const dt = date.getDate()

  const cancelHandler = () => {
    setModal({
      modalHeader: 'Oops! This feature is still in development :(',
      modalMessage: 'Please send an email to info@userosa.com to cancel your plan.',
      modalButtonName: 'Got It!',
      modalFunction: 'cancelPlan',
      modalCancelButton: false,
      modalFunctionButtonColor: 'green'
    })
  }

  return (
    <div className='right-menu-body-profile'>
      <div className='menu-body-container'>
        <div className='menu-body-cards-profile'>
          <div className='plan-menu-card-change'>
            <p className='card-header'>Change Plan</p>
            <div className='plan-mid-content'>
              <div className='current-plan-content'>
                <div className='current-plan-content-left'>
                  <span className='bold-content'>Current Plan: </span> {user.plan}{' '}
                  {user.plan === 'Free' ? '/ 5 credits' : ''}
                </div>
              </div>
              <div className='plan-renew-content'>
                <div className='plan-renew-content-left'>
                  <span className='bold-content'>Plan start date: </span>
                  {monthName + ' ' + dt}
                </div>
              </div>
            </div>

            <Link className='change-plan-button' to='/credits'>
              CHANGE PLAN
            </Link>

            <div className='logout-section'>
              <div onClick={cancelHandler} className='logout-button'>
                Cancel Plan
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
