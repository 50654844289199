import React, { useContext } from 'react';
import MatchCard from '../layout/MatchCard';

import LikeContext from '../../context/like/likeContext';

export default function RightMenuBodyMatches() {
  const likeContext = useContext(LikeContext);
  const { likes } = likeContext;

  const matchedLikes = likes.filter((like) => {
    return like.isMatched;
  });
  console.log(likes);
  return (
    <div
      className={
        matchedLikes.length === 0 ? 'right-menu-body-empty' : 'right-menu-body'
      }
    >
      <div className="menu-body-container">
        <div className="menu-body-cards">
          {matchedLikes.map((like) => (
            <MatchCard key={like._id} like={like} />
          ))}
        </div>

        {matchedLikes.length === 0 ? (
          <div className="section-message section-message-add">
            You don't have any matches yet.
          </div>
        ) : null}

        {/* <div className='menu-body-plus-area'>
          <div className='menu-body-plus-area-content'>
            {matchedLikes.length === 0 ? (
              <div className='section-message section-message-add'>
                You don't have any matches yet.
              </div>
            ) : null}
          </div>
        </div> */}
      </div>
    </div>
  );
}
