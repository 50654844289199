import React, { useContext, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import DashboardLeft from '../layout/DashboardLeft'
import RightMenuHead from '../layout/RightMenuHead'
import ChatroomsColumn from '../layout/ChatroomsColumn'
import RightMenuHeadMessages2 from '../layout/RightMenuHeadMessages2'
import RightMenuBodyMessages from '../layout/RightMenuBodyMessages'
import DashboardFooter from '../layout/DashboardFooter'
import Flash from '../layout/Flash'

import ChatroomContext from '../../context/chatroom/chatroomContext'
import AuthContext from '../../context/auth/authContext'
import FlashContext from '../../context/flash/flashContext'

export default function Messages() {
  const chatroomContext = useContext(ChatroomContext)
  const authContext = useContext(AuthContext)
  const flashContext = useContext(FlashContext)

  const { getChatrooms, chatRooms, error } = chatroomContext
  const { loadUser, user } = authContext
  const { flash, setFlash, resetFlash } = flashContext

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user === null) {
      loadUser()
    }
    if (!chatRooms || chatRooms.length === 0) {
      getChatrooms()
    }
    return () => {
      resetFlash()
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (error) {
      setFlash({
        type: 'red',
        message: 'There was a problem. Please try again.',
        duration: 5000,
        id: uuidv4()
      })
    }
    //eslint-disable-next-line
  }, [error])

  return (
    <div className='dashboard-body'>
      <div className='bg-left'></div>
      <div className='bg-right'></div>
      <div className='container'>
        {flash && <Flash />}
        <DashboardLeft pageName={'Messages'} />
        <div className='right-menu-messages'>
          <div className='messages-left-bundle'>
            <RightMenuHead title='Messages' />
            <ChatroomsColumn />
            <DashboardFooter pageName={'Messages'} />
          </div>

          <div className='messages-right-bundle'>
            <RightMenuHeadMessages2 />
            <RightMenuBodyMessages />
          </div>
        </div>
      </div>
    </div>
  )
}
