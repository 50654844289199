import React, { useReducer } from 'react'
import FlashContext from './flashContext'
import flashReducer from './flashReducer'

import { SET_FLASH, REMOVE_FLASH, RESET_FLASH } from '../types'

const FlashState = props => {
  const initialState = {
    flash: false,
    flashMessagesArray: []
  }

  const [state, dispatch] = useReducer(flashReducer, initialState)

  //SET A FLASH MESSAGE
  const setFlash = props => {
    dispatch({ type: SET_FLASH, payload: props })
    removeFlash(props)
  }

  //CLEAR FLASH MESSAGE
  const removeFlash = props => {
    setTimeout(() => {
      dispatch({
        type: REMOVE_FLASH,
        payload: props.id
      })
    }, props.duration)
  }

  //Create a flash message
  const resetFlash = () => {
    dispatch({
      type: RESET_FLASH
    })
  }

  return (
    <FlashContext.Provider
      value={{
        flash: state.flash,
        flashMessagesArray: state.flashMessagesArray,
        setFlash,
        removeFlash,
        resetFlash
      }}
    >
      {props.children}
    </FlashContext.Provider>
  )
}

export default FlashState
