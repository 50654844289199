import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import AuthContext from '../../context/auth/authContext'
import FlashContext from '../../context/flash/flashContext'

export default function Login(props) {
  const authContext = useContext(AuthContext)
  const flashContext = useContext(FlashContext)

  const { verifyEmail, emailVerified } = authContext
  const { setFlash } = flashContext

  const { token } = useParams()

  useEffect(() => {
    async function waitFunc() {
      await localStorage.setItem('token', token)
      verifyEmail(token)
    }
    waitFunc()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (emailVerified) {
      props.history.push('/home')
      setFlash({
        type: 'green',
        message: 'Your email address has been verified successfully.',
        duration: 5000,
        id: uuidv4()
      })
    }
    if (emailVerified !== null && emailVerified === false) {
      props.history.push('/login')
      setFlash({
        type: 'red',
        message: 'An error occured while trying to verify your email address. Please try again.',
        duration: 5000,
        id: uuidv4()
      })
    }
    //eslint-disable-next-line
  }, [emailVerified])

  return (
    <>
      <section id='login-main-section'>
        <div className='login-content'>
          <h3>Working...</h3>
        </div>
      </section>
    </>
  )
}
