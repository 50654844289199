import React, { useContext, useEffect } from 'react'

import AuthContext from '../../context/auth/authContext'
import ChatroomContext from '../../context/chatroom/chatroomContext'

export default function MessageText({ message, last }) {
  const authContext = useContext(AuthContext)
  const { user } = authContext

  const chatroomContext = useContext(ChatroomContext)
  const { currentChatroomID, readMessages } = chatroomContext

  useEffect(() => {
    if (!last) {
      readMessages(currentChatroomID)
    }
    // eslint-disable-next-line
  }, [currentChatroomID])

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const date = new Date(message.date)
  const year = date.getFullYear()
  const month = date.getMonth()
  const monthName = monthNames[month]
  const dt = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()

  const todaysDate = new Date()
  const todaysMonth = todaysDate.getMonth()
  const todaysMonthName = monthNames[todaysMonth]
  const todaysDt = todaysDate.getDate()

  return (
    <>
      {message.from === user._id ? (
        <>
          <div className={last ? 'message-self-last' : 'message-self'}>{message.text}</div>
          {last ? (
            <div className='last-message-date-self'>
              {todaysDt === dt && todaysMonthName === monthName
                ? 'Today' +
                  ' ' +
                  (hour > 12 ? hour - 12 : hour) +
                  ':' +
                  ('0' + minute).slice(-2) +
                  ' ' +
                  (hour > 12 ? 'PM' : 'AM')
                : monthName.substring(0, 3) +
                  ' ' +
                  dt +
                  ', ' +
                  year +
                  ', ' +
                  (hour > 12 ? hour - 12 : hour) +
                  ':' +
                  ('0' + minute).slice(-2) +
                  ' ' +
                  (hour > 12 ? 'PM' : 'AM')}
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        <>
          <div className={last ? 'message-other-last' : 'message-other'}>{message.text}</div>
          {last ? (
            <div className='last-message-date-other'>
              {todaysDt === dt && todaysMonthName === monthName
                ? 'Today' +
                  ' ' +
                  (hour > 12 ? hour - 12 : hour) +
                  ':' +
                  ('0' + minute).slice(-2) +
                  ' ' +
                  (hour > 12 ? 'PM' : 'AM')
                : monthName.substring(0, 3) +
                  ' ' +
                  dt +
                  ', ' +
                  year +
                  ', ' +
                  (hour > 12 ? hour - 12 : hour) +
                  ':' +
                  ('0' + minute).slice(-2) +
                  ' ' +
                  (hour > 12 ? 'PM' : 'AM')}
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </>
  )
}
