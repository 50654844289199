import React, { useContext, useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import ChatroomContext from '../../context/chatroom/chatroomContext';
import AuthContext from '../../context/auth/authContext';

import MessageText from '../layout/MessageText';

export default function RightMenuBodyMessages() {
  const [messageInput, setMessageInput] = useState('');
  const [localMessages, setLocalMessages] = useState([]);
  const [startConvo, setStartConvo] = useState(false);
  const [bigFooter, setBigFooter] = useState(false);
  const [removeEmptyText, setRemoveEmptyText] = useState(false);

  const { sender, reciever } = useParams();
  const chatroomContext = useContext(ChatroomContext);
  const {
    chatRooms,
    getChatrooms,
    loading,
    currentChatRoom,
    getMessages,
    messages,
    clearMessages,
    clearCurrentChatRoom,
    sendMessage,
    readMessages,
    readMessagesOnlyUI,
    currentChatroomID,
    updateLastMessage,
    messageSent,
  } = chatroomContext;

  const authContext = useContext(AuthContext);
  const {
    socket,
    newMessage,
    updateMessages,
    readMessageNow,
    emptyNewMessages,
  } = authContext;

  const chatLog = useRef(null);
  const messageInputRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 950) {
      messageInputRef.current.blur();
    }
    if (typeof window !== 'undefined' && window.innerWidth > 950) {
      messageInputRef.current.focus();
    }
    let maxScroll = document.body.scrollHeight;
    window.scrollTo(0, maxScroll);
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;
        if (currentScrollPos > 50) {
          setBigFooter(true);
        } else {
          setBigFooter(false);
        }
      };
    }
    return () => {
      clearCurrentChatRoom();
      clearMessages();
      setRemoveEmptyText(false);
      // readMessagesOnlyUI(readMessageNow.chatroomID)
    };
    //eslint-disable-next-line
  }, []);

  //Auto scroll
  useEffect(() => {
    if (localMessages.length > 0) {
      let maxScroll = document.body.scrollHeight + window.innerHeight;
      window.scrollTo(0, maxScroll);
    }
  }, [localMessages]);

  // useEffect(() => {
  //   if (chatRooms) {
  //     //Not sure
  //     if (currentChatroomID) {
  //       readMessages(currentChatroomID)
  //       console.log('moveee')
  //     }
  //   }
  // }, [chatRooms])

  useEffect(() => {
    if (messageSent) {
      socket.emit('ready-to-be-read', {
        socketID: socket.id,
        chatroomID: currentChatroomID,
        to: reciever,
      });
    }
    //eslint-disable-next-line
  }, [messageSent]);

  useEffect(() => {
    if (
      chatRooms &&
      readMessageNow &&
      readMessageNow.chatroomID === currentChatroomID
    ) {
      readMessagesOnlyUI(readMessageNow.chatroomID);
      // readMessages(readMessageNow.chatroomID)
      setTimeout(() => {
        readMessages(readMessageNow.chatroomID);
      }, 5000);
    }
    //eslint-disable-next-line
  }, [readMessageNow]);

  useEffect(() => {
    if (currentChatroomID && chatRooms && chatRooms.length > 0) {
      readMessages(currentChatroomID);
      emptyNewMessages();
    }
    //eslint-disable-next-line
  }, [currentChatroomID]);

  useEffect(() => {
    if (messages) {
      setLocalMessages(messages);
      getChatrooms();
    }
    if (messages && messages.length === 0) {
      setStartConvo(true);
    }
    if (messages && messages.length > 0) {
      setStartConvo(false);
    }
    //eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    if (newMessage && newMessage.length && currentChatroomID) {
      setLocalMessages((prev) => [newMessage[0], ...prev]);
      setTimeout(() => {
        readMessages(currentChatroomID);
      }, 5000);

      updateLastMessage({
        id: newMessage[0].chatroomID,
        date: Date.now(),
        from: newMessage[0].from,
        to: newMessage[0].to,
        read: true,
        text: newMessage[0].text,
      });
      emptyNewMessages();
    }
    //eslint-disable-next-line
  }, [newMessage]);

  useEffect(() => {
    if (currentChatRoom && currentChatRoom.lastMessage) {
      getMessages(currentChatRoom.lastMessage.chatroomID);
      getChatrooms();
    }
    clearMessages();
    setLocalMessages([]);
    //eslint-disable-next-line
  }, [currentChatRoom]);

  const onChange = (e) => {
    setMessageInput(e.target.value);
  };

  const onSubmit = (e) => {
    if (messageInput !== '') {
      e.preventDefault();
      sendMessage({
        to: reciever,
        text: messageInput,
      });
      socket.emit('chat-message-from-client', {
        socketID: socket.id,
        from: sender,
        to: reciever,
        text: messageInput,
        date: Date.now(),
        chatroomID: currentChatroomID,
      });
      updateMessages({
        socketID: socket.id,
        from: sender,
        to: reciever,
        text: messageInput,
        date: Date.now(),
        chatroomID: currentChatroomID,
        _id: uuidv4(),
      });
      updateLastMessage({
        id: currentChatroomID,
        date: Date.now(),
        from: sender,
        to: reciever,
        read: true,
        text: messageInput,
      });
      setMessageInput('');
      setRemoveEmptyText(true);
    }
  };

  const onFocus = (e) => {
    e.preventDefault();
    let maxScroll = document.body.scrollHeight;
    window.scrollTo(0, maxScroll);
  };

  return (
    <>
      <div className="right-menu-body-messages">
        <div ref={chatLog} className="menu-body-plus-area-content-2">
          <div ref={chatLog} className="inside">
            {!loading &&
              startConvo &&
              !removeEmptyText &&
              localMessages.length === 0 && (
                <div className="spinner-container">
                  Create a new conversation by sending a message!
                </div>
              )}

            {loading ? (
              <div className="spinner-container">
                <i className="fas fa-spinner fa-spin spinner-3"></i>
              </div>
            ) : (
              <>
                {localMessages
                  ? localMessages
                      .map((e) => {
                        return (
                          <MessageText
                            key={e._id}
                            message={e}
                            index={messages ? messages.indexOf(e) : 0}
                            length={messages ? messages.length : 1}
                            last={
                              localMessages.indexOf(e) === 0 ||
                              e.from !==
                                localMessages[localMessages.indexOf(e) - 1]
                                  .from ||
                              Date.parse(
                                localMessages[localMessages.indexOf(e) - 1].date
                              ) -
                                Date.parse(e.date) >
                                60000
                            }
                          />
                        );
                      })
                      .reverse()
                  : ''}
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className={
          bigFooter
            ? 'messages-body-bottom-bundle-big'
            : 'messages-body-bottom-bundle'
        }
      >
        <form
          onSubmit={onSubmit}
          className="messages-body-bottom-bundle-container"
        >
          <input
            onChange={onChange}
            ref={messageInputRef}
            className="message-input"
            type="text"
            placeholder="Message..."
            name="message-input"
            value={messageInput}
            autoComplete="off"
            onFocus={onFocus}
          />
          <i
            onClick={onSubmit}
            className="fas fa-paper-plane send-message-icon"
          ></i>
        </form>
      </div>
    </>
  );
}
