import React, { useContext, useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import AuthContext from '../../context/auth/authContext'
import FlashContext from '../../context/flash/flashContext'

export default function VerifyUsername() {
  const authContext = useContext(AuthContext)
  const {
    user,
    verifyUsername,
    loadUser,
    usernameVerified,
    error,
    clearErrors,
    clearSuccess
  } = authContext

  const flashContext = useContext(FlashContext)
  const { setFlash, resetFlash } = flashContext

  const [clipboardBlue, setClipboardBlue] = useState(false)
  const [loadingIconTrue, setLoadingIconTrue] = useState(false)
  const [verifiedIconTrue, setVerifiedIconTrue] = useState(false)
  const [errorIconTrue, setErrorIconTrue] = useState(false)

  useEffect(() => {
    return () => {
      resetFlash()
      clearErrors()
      clearSuccess()
    }
    //eslint-disable-next-line
  }, [])

  const copyToClipboard = () => {
    setClipboardBlue(true)
    setFlash({
      type: 'blue',
      message: 'Copied to your clipboard.',
      duration: 3000,
      id: uuidv4()
    })
    navigator.clipboard.writeText(user.verificationCode.toUpperCase())
  }

  const verifyUser = () => {
    setLoadingIconTrue(true)
    verifyUsername()
  }

  //After usernameVerified is true
  useEffect(() => {
    if (usernameVerified) {
      setLoadingIconTrue(false)
      setVerifiedIconTrue(true)
      setFlash({
        type: 'green',
        message: 'Username verified successfully! Please wait...',
        duration: 5000,
        id: uuidv4()
      })

      setTimeout(() => {
        loadUser()
      }, 6000)
    }
    //eslint-disable-next-line
  }, [usernameVerified])

  //After an error occured
  useEffect(() => {
    if (error !== undefined && error !== null && error[0]) {
      setLoadingIconTrue(false)
      setErrorIconTrue(true)
      myFunction2()
      setFlash({
        type: 'red',
        message: error[0],
        duration: 8000,
        id: uuidv4()
      })
    }
    //eslint-disable-next-line
  }, [error])

  var myVar

  function myFunction2() {
    myVar = setTimeout(function () {
      setErrorIconTrue(false)
    }, 2000)
  }

  //Copy button animation
  useEffect(() => {
    myFunction()
    return myStopFunction
    //eslint-disable-next-line
  }, [clipboardBlue])

  function myFunction() {
    myVar = setTimeout(function () {
      setClipboardBlue(false)
    }, 2500)
  }

  function myStopFunction() {
    clearTimeout(myVar)
  }

  return (
    <div className='verify-username'>
      <div className='verify-username-card'>
        <div className='verify-username-container'>
          <div className='verify-username-header'>
            Welcome to Rosa <span className='verify-username-name'>{'@' + user.username}</span>
          </div>
          <div className='verify-username-text'>
            {/* Before we start, we need to confirm that this Instagram username is yours. First, go to
            your Instagram, and paste your code to your Instagram Bio. Then come back, and click
            "Verify" button below. This is only a one-time process and you may remove the code
            afterwards. */}
            Click the red "Copy the code" button below, and go to your Instagram account. Then go to
            Profile => Edit Profile => Bio. Paste the code somewhere, and hit "Done". Come back to
            Rosa. Click the big blue "VERIFY" button below.
          </div>
          <div className='verify-username-code-bundle'>
            <div className='verification-code'>
              {'Your code: ' + user.verificationCode.toUpperCase()}
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={copyToClipboard}
              className={
                clipboardBlue
                  ? 'verification-code-copy-button-blue'
                  : 'verification-code-copy-button-red'
              }
            >
              {clipboardBlue ? 'Copied!' : 'Copy the code'}
            </div>
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={verifyUser}
            className={
              loadingIconTrue || verifiedIconTrue || errorIconTrue
                ? 'verify-username-button-disabled'
                : 'verify-username-button'
            }
          >
            {loadingIconTrue ? (
              <i className='fas fa-spinner fa-spin spinner-2'></i>
            ) : '' || verifiedIconTrue ? (
              <i className='fas fa-check'></i>
            ) : '' || errorIconTrue ? (
              <i className='fas fa-times'></i>
            ) : (
              ''
            )}

            <span className='verify-itself'>
              {/* {loadingIconTrue ? 'Verifying...' : 'VERIFY' || errorIconTrue ? 'Failed' : 'VERIFY'} */}
              {loadingIconTrue
                ? 'Verifying...'
                : '' || verifiedIconTrue
                ? 'Verified!'
                : '' || errorIconTrue
                ? 'Failed!'
                : 'VERIFY'}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
