import React, { useReducer } from 'react'
import LikeContext from './likeContext'
import likeReducer from './likeReducer'
import axios from 'axios'

import {
  GET_LIKES,
  GET_PLANS,
  ADD_LIKE,
  DELETE_LIKE,
  FILTER_LIKES,
  CLEAR_LIKE_FILTER,
  CLEAR_LIKES,
  LIKE_ERROR,
  SEARCH_FOCUSED,
  NEW_MATCH,
  NEW_MATCH_FALSE
} from '../types'

const LikeState = props => {
  const initialState = {
    likes: [],
    plans: [],
    filteredLikes: null,
    filteredMessages: null,
    error: null,
    searchFocused: false,
    newMatch: false
  }

  const [state, dispatch] = useReducer(likeReducer, initialState)

  //GET LIKES
  const getLikes = async () => {
    try {
      const res = await axios.get('/api/likes')
      dispatch({ type: GET_LIKES, payload: res.data })
    } catch (err) {
      dispatch({ type: LIKE_ERROR, payload: err.response.msg })
    }
  }

  //GET ALL PLANS
  const getPlans = async () => {
    try {
      const res = await axios.get('/api/likes/plans')
      dispatch({ type: GET_PLANS, payload: res.data })
    } catch (err) {
      dispatch({ type: LIKE_ERROR, payload: err.response.msg })
    }
  }

  //ADD NEW LIKE
  const addLike = async like => {
    const config = {
      header: {
        'Content-Type': 'application/json'
      }
    }

    try {
      const res = await axios.post('/api/likes', like, config)
      dispatch({ type: ADD_LIKE, payload: res.data })
      if (res.data.isMatched) {
        dispatch({ type: NEW_MATCH })
      }
    } catch (err) {
      dispatch({ type: LIKE_ERROR, payload: err.response.msg })
    }
  }

  const newMatchFalse = () => {
    dispatch({ type: NEW_MATCH_FALSE })
  }

  //DELETE A LIKE
  const deleteLike = async _id => {
    try {
      await axios.delete(`api/likes/${_id}`)
      dispatch({ type: DELETE_LIKE, payload: _id })
    } catch (err) {
      dispatch({ type: LIKE_ERROR, payload: err.response.msg })
    }
  }

  //CLEAR LIKES
  const clearLikes = () => {
    dispatch({ type: CLEAR_LIKES })
  }

  //SET CURRENT LIKE

  //CLEAR CURRENT LIKE

  //FILTER LIKES
  const filterLikes = text => {
    dispatch({ type: FILTER_LIKES, payload: text })
  }

  //CLEAR LIKE FILTER
  const clearLikeFilter = text => {
    dispatch({ type: CLEAR_LIKE_FILTER })
  }

  //SET SEARCH FOCUSED TRUE
  const setSearchFocused = text => {
    dispatch({ type: SEARCH_FOCUSED })
  }

  // //FILTER MESSAGES
  // const filterMessages = text => {
  //   dispatch({ type: FILTER_MESSAGES, payload: text })
  // }
  // //CLEAR  MESSAGE FILTER
  // const clearMessageFilter = text => {
  //   dispatch({ type: CLEAR_MESSAGE_FILTER })
  // }

  return (
    <LikeContext.Provider
      value={{
        likes: state.likes,
        plans: state.plans,
        filteredLikes: state.filteredLikes,
        error: state.error,
        searchFocused: state.searchFocused,
        newMatch: state.newMatch,
        // filterMessages: filteredMessages,
        getLikes,
        addLike,
        deleteLike,
        filterLikes,
        clearLikeFilter,
        clearLikes,
        getPlans,
        setSearchFocused,
        newMatchFalse
        // filterMessages,
        // clearMessageFilter
      }}
    >
      {props.children}
    </LikeContext.Provider>
  )
}

export default LikeState
