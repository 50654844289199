import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import DashboardLeft from '../layout/DashboardLeft';
import RightMenuHead from '../layout/RightMenuHead';
import ChatroomsColumn from '../layout/ChatroomsColumn';
import RightMenuHeadMessages2 from '../layout/RightMenuHeadMessages2';
import RightMenuBodyMessages1 from '../layout/RightMenuBodyMessages1';
import Flash from '../layout/Flash';

import ChatroomContext from '../../context/chatroom/chatroomContext';
import AuthContext from '../../context/auth/authContext';
import LikeContext from '../../context/like/likeContext';
import FlashContext from '../../context/flash/flashContext';

export default function Messages() {
  const chatroomContext = useContext(ChatroomContext);
  const authContext = useContext(AuthContext);
  const likeContext = useContext(LikeContext);
  const flashContext = useContext(FlashContext);

  const {
    getChatrooms,
    chatRooms,
    getMessagesAlternate,
    error,
    messages,
  } = chatroomContext;
  const { loadUser, user } = authContext;
  const { likes, getLikes } = likeContext;
  const { flash, setFlash, resetFlash } = flashContext;

  const { sender, reciever } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user === null) {
      loadUser();
    }
    if (
      !chatRooms ||
      chatRooms.length === 0 ||
      typeof chatRooms === undefined
    ) {
      getChatrooms();
    }
    if (messages === null) {
      getMessagesAlternate({ peer1: sender, peer2: reciever });
    }
    if (likes.length === 0) {
      getLikes();
    }

    return () => {
      resetFlash();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      setFlash({
        type: 'red',
        message: 'There was a problem. Please try again.',
        duration: 5000,
        id: uuidv4(),
      });
    }
    //eslint-disable-next-line
  }, [error]);

  return (
    <>
      <div className="dashboard-body">
        <div className="bg-left"></div>
        <div className="bg-right"></div>
        <div className="container">
          {flash && <Flash />}
          <DashboardLeft pageName={'Messages'} />
          <div className="right-menu-messages">
            <div className="messages-left-bundle-1">
              <RightMenuHead title="Messages" />
              <ChatroomsColumn />
            </div>

            <div className="messages-right-bundle-1">
              <RightMenuHeadMessages2 />
              <RightMenuBodyMessages1 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
