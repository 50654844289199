import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  CLEAR_ERRORS,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REFRESH_CREDITS,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
  VERIFY_SUCCESS,
  VERIFY_FAIL,
  VERIFY_USERNAME_SUCCESS,
  VERIFY_USERNAME_FAIL,
  SUCCESS,
  CLEAR_SUCCESS,
  SOCKET_CONNECTED,
  NEW_MESSAGE_FROM_SERVER,
  NEW_MESSAGE_FROM_MYSELF,
  CARD_INFO,
  READ_MESSAGE_NOW,
  EMPTY_NEW_MESSAGES,
  REGISTER_FALSEN,
} from '../types'

export default (state, action) => {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
        credits: action.payload.credits,
      }
    case REGISTER_SUCCESS:
      localStorage.setItem('token', action.payload.token)
      sessionStorage.clear()
      return {
        ...state,
        ...action.payload,
        registerSuccess: true,
      }
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token)
      sessionStorage.clear()
      return {
        ...state,
        ...action.payload,
        loginSuccess: true,
      }
    case REGISTER_FAIL:
      localStorage.removeItem('token')
      sessionStorage.clear()
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null,
        error: [action.payload, ...state.error],
      }
    case AUTH_ERROR:
    case LOGIN_FAIL:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: true,
        user: null,
        error: [action.payload, ...state.error],
      }
    case LOGOUT:
      localStorage.removeItem('token')
      sessionStorage.clear()
      return {
        ...state,
        loginSuccess: false,
        token: null,
        isAuthenticated: null,
        loading: true,
        user: null,
        // socket: null,
        success: null,
        credits: null,
        emailVerified: null,
        newMessage: [],
        readMessageNow: false,
        usernameVerified: false,
      }
    case REGISTER_FALSEN:
      return {
        ...state,
        registerSuccess: false,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: [],
      }
    case CLEAR_SUCCESS:
      return {
        ...state,
        success: null,
      }
    case REFRESH_CREDITS:
      return {
        ...state,
        ...action.payload,
        credits: action.payload.credits,
      }
    case EMAIL_SEND_SUCCESS:
      return {
        ...state,
        verificationEmailSent: true,
        emailSentCount: state.emailSentCount + 1,
      }
    case EMAIL_SEND_FAIL:
      return {
        ...state,
        // error: action.payload
      }
    case VERIFY_SUCCESS:
      return {
        ...state,
        emailVerified: true,
      }
    case VERIFY_FAIL:
      return {
        ...state,
        emailVerified: false,
      }
    case VERIFY_USERNAME_SUCCESS:
      return {
        ...state,
        usernameVerified: true,
      }
    case VERIFY_USERNAME_FAIL:
      return {
        ...state,
        error: [action.payload],
      }
    case SUCCESS:
      return {
        ...state,
        success: [action.payload],
      }
    case CARD_INFO:
      return {
        ...state,
        cardInfo: action.payload,
      }
    case SOCKET_CONNECTED:
      return {
        ...state,
        socket: action.payload,
      }
    case NEW_MESSAGE_FROM_SERVER:
      return {
        ...state,
        newMessage: [action.payload, ...state.newMessage],
      }
    case NEW_MESSAGE_FROM_MYSELF:
      return {
        ...state,
        newMessage: [action.payload, ...state.newMessage],
      }
    case EMPTY_NEW_MESSAGES:
      return {
        ...state,
        newMessage: [],
      }
    case READ_MESSAGE_NOW:
      return {
        ...state,
        readMessageNow: action.payload,
      }
    default:
      return state
  }
}
