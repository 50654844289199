import React, { Fragment, useContext, useEffect } from 'react'
import LikeItem from './LikeItem'
import Spinner from '../layout/Spinner'
import LikeContext from '../../context/like/likeContext'

export default function Likes() {
  const likeContext = useContext(LikeContext)

  const { likes, filteredLikes, getLikes, loading } = likeContext

  useEffect(() => {
    getLikes()
    // eslint-disable-next-line
  }, [])

  if (likes !== null && likes.length === 0 && !loading) {
    return <h4>You don't have any liked profile. Please add one.</h4>
  }

  return (
    <Fragment>
      {likes !== null && !loading ? (
        <Fragment>
          {filteredLikes !== null
            ? filteredLikes.map(el => <LikeItem key={el._id} like={el} />)
            : likes.map(el => <LikeItem key={el._id} like={el} />)}
        </Fragment>
      ) : (
        <Spinner />
      )}
    </Fragment>
  )
}
