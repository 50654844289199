import React, { useState, useEffect } from 'react'

export default function FlashItem(e) {
  const [animation, setAnimation] = useState(true)

  useEffect(() => {
    const timer = () => {
      setTimeout(() => {
        setAnimation(false)
      }, e.flash.duration - 300)
    }
    timer()
    return () => {
      clearTimeout(timer)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <div
      className={
        'flash-item ' +
        ('flash-item-' + e.flash.type) +
        ' ' +
        (animation ? 'flash-animation-add' : 'flash-animation-remove')
      }
    >
      {e.flash.type === 'red' && <i className='fas fa-exclamation'></i>}
      {e.flash.type === 'green' && <i className='fas fa-check fa-check-icon'></i>}
      {e.flash.message}
    </div>
  )
}
