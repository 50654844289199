import React, { Fragment } from 'react'
import spinner from './spinner.png'

export default function Spinner() {
  return (
    <Fragment>
      <img
        src={spinner}
        style={{ width: '200px', margin: 'auto', display: 'block' }}
        alt='Loading...'
      />
    </Fragment>
  )
}
