import React, { useContext, useState } from 'react'
import StripeCheckout from 'react-stripe-checkout'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import AuthContext from '../../context/auth/authContext'
import FlashContext from '../../context/flash/flashContext'

export default function PlanCard({ plan }) {
  const StripeKey = 'pk_live_OWWll9BuAMrrtb1SLJCEDV8w'
  // const StripeKey = 'pk_test_8xSABsZdL1fTW6WsuKwXtagF'
  const authContext = useContext(AuthContext)
  const { user, loadUser } = authContext

  const flashContext = useContext(FlashContext)
  const { setFlash, resetFlash } = flashContext

  const { planName, monthlyCredits, planPrice } = plan

  const [processing, setProcessing] = useState(false)

  const onClick = (e) => {
    e.preventDefault()
  }

  const paymentHandler = async (token) => {
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    }
    const body = {
      customerMongoID: user._id,
      stripeID: user.stripeID,
      product: plan,
      token,
    }
    try {
      setProcessing(true)
      await axios.post('/api/payment', body, config)
      resetFlash()
      setFlash({
        type: 'green',
        message: 'Payment successfull. Please wait...',
        duration: 8000,
        id: uuidv4(),
      })
      loadUser()
    } catch (err) {
      console.log('Error is: ', err)
      console.log(err.response)
      resetFlash()
      setFlash({
        type: 'red',
        message: err.response.data.msg,
        duration: 15000,
        id: uuidv4(),
      })
    }
  }

  const popupClosed = () => {
    if (processing) {
      setFlash({
        type: 'blue',
        message: 'Payment processing...',
        duration: 15000,
        id: uuidv4(),
      })
    }
  }

  return (
    <div
      className={
        planName === 'Premium'
          ? 'premium-plan'
          : '' || planName === 'Advanced'
          ? 'advanced-plan'
          : '' || planName === 'Basic'
          ? 'basic-plan'
          : ''
      }
    >
      <div className="plan-container">
        <div className="plan-content-left">
          <h3>{planName.toUpperCase()}</h3>
          <p className="monthly-credits">
            <span className="bold-content credits-bold">Monthly credits: </span>{' '}
            {planName === 'Premium' ? 'Unlimited' : monthlyCredits + ' credits'}
          </p>
          <p className="price">
            <span className="bold-content credits-bold">Price: </span>{' '}
            {'$' + planPrice + '/month'}
          </p>
        </div>
        {planName === user.plan ? (
          <div className="current-plan-button">
            <span className="button-flex">CURRENT</span>
          </div>
        ) : (
          <StripeCheckout
            onClick={onClick}
            stripeKey={StripeKey}
            token={paymentHandler}
            name={user.username}
            description={'Rosa ' + planName + ' Plan'}
            image={require('./icons-profile2.png')}
            email={user.email}
            zipCode={false}
            amount={planPrice * 100} // cents
            currency="USD"
            panelLabel="Pay"
            autoComplete="off"
            closed={popupClosed}
          >
            <div onClick={onClick} className="buy-button">
              <span className="button-flex">BUY</span>
            </div>
          </StripeCheckout>
        )}
      </div>
    </div>
  )
}
