import React, { useContext, useEffect } from 'react'
import DashboardLeft from '../layout/DashboardLeft'
import RightMenuHead from '../layout/RightMenuHead'
import RightMenuBodyMatches from '../layout/RightMenuBodyMatches'
import DashboardFooter from '../layout/DashboardFooter'
import Landing from '../pages/Landing'

import AuthContext from '../../context/auth/authContext'
import LikeContext from '../../context/like/likeContext'
import ChatroomContext from '../../context/chatroom/chatroomContext'

export default function Matches(props) {
  const authContext = useContext(AuthContext)
  const { loading, loadUser, isAuthenticated, user } = authContext

  const chatroomContext = useContext(ChatroomContext)
  const { getChatrooms, chatrooms } = chatroomContext

  const likeContext = useContext(LikeContext)
  const { getLikes } = likeContext

  useEffect(() => {
    if (!user) {
      loadUser()
    }
    if (chatrooms && chatrooms.length === 0) {
      getChatrooms()
    }
    getLikes()
    window.scrollTo(0, 0)
    //eslint-disable-next-line
  }, [])

  if (loading) {
    return <h1>Loading...</h1>
  }

  if (!isAuthenticated) {
    return <Landing />
  }

  return (
    <div className='dashboard-body'>
      <div className='bg-left'></div>
      <div className='bg-right'></div>

      <div className='container'>
        <DashboardLeft pageName={'Matches'} />
        <div className='right-menu'>
          <RightMenuHead title={'Matches'} />
          <RightMenuBodyMatches />
          <DashboardFooter pageName={'Matches'} />
        </div>
      </div>
    </div>
  )
}
