import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
    //eslint-disable-next-line
  }, [])
  return (
    <div>
      <nav
        id="navbar"
        style={{
          width: '100%',
        }}
      >
        <Link to="/" className="header-logo-black">
          <img src="/logo-black.svg" alt="" />
        </Link>
        
      </nav>

      <div className="page privacy-page-main">
        <h1>Privacy Policy</h1>

        <p>
          The website <a href="https://www.userosa.com">userosa.com</a> is owned and
          maintained by Kozmonot Inc. All the visitors, users, customers,
          contributors are named as "users"; and the website{' '}
          <a href="https://www.userosa.com">userosa.com</a> is termed as "website"
          everywhere in this document.
        </p>
        <p>
          We are committed to protecting your privacy online. This privacy
          policy explains what information we collect from you or what
          information you share with us when you visit the website. We review
          our policy from time to time, so you are advised to check the latest
          version.
        </p>
        <p>
          As a user of <a href="https://www.userosa.com">userosa.com</a>, you must
          agree to have read our privacy policy before sharing any information
          with us. This Privacy Policy is incorporated into and subject to the
          Terms of Use.
        </p>
        <h2>Information we collect</h2>
        <p>
          The visitors are free to navigate through our website without
          providing any personally identifiable information if they do not want
          to register with us.
        </p>
        <p>
          To gain access to some of our products and services, you need to
          register with us and obtain an account, email, and password. If you
          choose to register with us, and are 18 years of age or older, we will
          collect your personally identifiable information such as your
          username, email address. Also, we collect more information such as
          your credit card information, for online shopping on
          shop.stingydeveloper.com, if you want to buy our products or services.
          We do not collect more information than is absolutely necessary to
          allow your participation in an activity on the website.
        </p>
        <p>
          We collect and analyze information about your general usage of the
          website, products, services, and courses. We might track your usage
          patterns to see what features of the website you commonly use, website
          traffic volume, frequency of visits, type and time of transactions,
          type of browser, browser language, IP address and operating system,
          and statistical information about how you use our products and
          services. We only collect, track and analyze such information in an
          aggregate manner that does not personally identify you. We try
          optimizing your website experience.&nbsp;
        </p>
        <h2>How do we use your information?</h2>
        <p>
          We do not sell your personal information to others. The personally
          identifiable Information you submit to receive information and
          services from our website will never be disclosed to any third party.
          We use this personally identifiable information to register you to
          access our services and verify your authority to access the courses
          and services.
        </p>
        <p>
          We use the collected information to improve our website and to send
          you relevant information about our products and services which we
          think may be of interest to you. If you have subscribed for a service
          on our website, then your personally identifiable information may be
          used to enable us to serve you better.
        </p>
        <p>
          We use the collected website information such as your usage patterns
          and how you access and use our products and services to help us
          improve our offerings and assist us in operating the website better.
        </p>
        <h2>Billing</h2>
        <p>
          If you as a registered user of our website use or provide services or
          products for which we implement a billing system for you, we will
          collect additional information from you so that we can process your
          payments. For example, we may collect your mailing address to remit
          payments.
        </p>
        <p>
          Please be informed that the information you supply while making a
          payment for an availed service is processed through a secure payment
          gateway and that we do not store such information, including your bank
          account details, in our server.
        </p>
        <h2>Disclosure of information</h2>
        <p>
          Should the company have its assets sold to or merge with another
          business entity, we reserve the right to transfer the information we
          receive from this website including your personally identifiable
          information to a successor as a result of any such corporate
          reorganization.
        </p>
        <h2>Retention of information</h2>
        <p>
          We will keep personally identifiable Information of our users for as
          long as they are registered subscribers or users of our products and
          services, and as permitted by law.
        </p>
        <h2>Information correction</h2>
        <p>
          To update or correct your personally identifiable information that we
          hold, you can notify us at info@userosa.com.
        </p>
        <h2>Information security</h2>
        <p>
          As a registered user, you can access your account with your email and
          a password as selected by you. You are responsible for keeping your
          password confidential. To ensure better security, we recommend that
          you choose a strong password containing alphabets, numbers, and
          special characters, and that you change your password periodically.
        </p>
        <p>
          We employ the best mechanisms possible to protect your Personal
          Information, however we cannot be held responsible for any breach of
          security unless it is caused as a direct result of our negligence.
          Unfortunately data transmission over the internet is not 100% secure
          and as a registered user of this website, you agree that we are not
          accountable for any data loss due to the limitations of the internet
          which are beyond our control.
        </p>
        <p>
          In the unlikely event of a breach in security systems, we may notify
          you through email so that you can take suitable protective measures.
          You are advised to inform us immediately at info@userosa.com in
          case your email or password is compromised.
        </p>
        <h2>Cookies Policy</h2>
        <p>
          We collect cookies to enhance our users experience and we have
          explained our cookies policy in simple words. You are requested to go
          through this policy to understand how do we collect and use cookies.
        </p>
        <h2>Updates &amp; Contact Info</h2>
        <p>
          From time to time, we may update this Policy. If we do, we’ll notify
          you by posting the policy on our site with a new effective date. If we
          make any material changes, we’ll take reasonable steps to notify you
          in advance of the planned change.
        </p>
        <p>
          If you have any questions about this privacy policy, please email us
          at info@userosa.com
        </p>
      </div>
    </div>
  );
}
