import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function DashboardFooter({ pageName }) {
  const [bigFooter, setBigFooter] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset
        if (currentScrollPos > 100) {
          setBigFooter(true)
        } else {
          setBigFooter(false)
        }
      }
    }
  }, [])

  return (
    <div id='dashboard-footer'>
      <div className={bigFooter ? 'footer-container-big' : 'footer-container'}>
        <Link to={'/home'}>
          <i
            className={'footer-icon fa-heart ' + (pageName === 'Likes' ? 'fas selected' : 'far')}
          ></i>
        </Link>
        <Link to={'/matches'}>
          <i
            className={
              'footer-icon fa-check-circle ' + (pageName === 'Matches' ? 'fas selected' : 'far')
            }
          ></i>
        </Link>
        <Link to={'/credits'}>
          <i
            className={'footer-icon fa-gem ' + (pageName === 'Credits' ? 'fas selected' : 'far')}
          ></i>
        </Link>
      </div>
    </div>
  )
}
