import { SET_MODAL, REMOVE_MODAL } from '../types'

export default (state, action) => {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...state,
        ...action.payload,
        modal: true
      }
    case REMOVE_MODAL:
      return {
        ...state,
        modal: false
      }
    default:
      return state
  }
}
