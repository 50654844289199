import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../context/auth/authContext'

import ProfilePic from './ProfilePic'

export default function DashboardLeft({ pageName }) {
  const authContext = useContext(AuthContext)
  const { user } = authContext

  return (
    <>
      <div className="left-menu">
        <div className="dashboard-logo">
          <img src="/logo-black.svg" alt="" />
        </div>
        <div className="dashboard-menu">
          <Link
            to={'/home'}
            className={'menu-item ' + (pageName === 'Likes' ? 'selected' : '')}
          >
            <i
              className={
                'fa-heart ' + (pageName === 'Likes' ? 'fas selected' : 'far')
              }
            ></i>
            <div className="menu-item-text">Likes</div>
          </Link>

          <Link
            to={'/matches'}
            className={
              'menu-item ' + (pageName === 'Matches' ? 'selected' : '')
            }
          >
            <div className="alert-parent">
              <i
                className={
                  'fa-check-circle ' +
                  (pageName === 'Matches' ? 'fas selected' : 'far')
                }
              ></i>
            </div>

            <div className="menu-item-text">Matches</div>
          </Link>

          <Link
            to={'/messages'}
            className={
              'menu-item ' + (pageName === 'Messages' ? 'selected' : '')
            }
          >
            <div className="alert-parent">
              <i
                className={
                  'fa-envelope ' +
                  (pageName === 'Messages' ? 'fas selected' : 'far')
                }
              ></i>
            </div>

            <div className="menu-item-text">Messages</div>
          </Link>

          <Link
            to={'/credits'}
            className={
              'menu-item ' + (pageName === 'Credits' ? 'selected' : '')
            }
          >
            <i
              className={
                'fa-gem ' + (pageName === 'Credits' ? 'fas selected' : 'far')
              }
            ></i>
            <div className="menu-item-text">Credits</div>
          </Link>

          <Link
            to={'/profile'}
            className={
              'menu-item ' + (pageName === 'Profile' ? 'selected' : '')
            }
          >
            <i
              className={
                'fa-user-circle ' +
                (pageName === 'Profile' ? 'fas selected' : 'far')
              }
            ></i>
            <div className="menu-item-text">Profile</div>
          </Link>
        </div>
        <Link to="/profile" className="left-profile-menu">
          <div className="menu-profile-pic">
            <ProfilePic />
          </div>

          <div className="menu-profile-data">
            <div className="menu-full-name">
              {user !== null ? user.username : null}
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}
