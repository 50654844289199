import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import AuthNavbar from '../layout/AuthNavbar'

const sendReq = async (authCode) => {
  console.log('sendReq triggered')
  let formdata = new FormData()
  formdata.append('client_id', '863677095053453')
  formdata.append('client_secret', '1ccac45713036043c2304b6248669ea4')
  formdata.append('grant_type', 'authorization_code')
  formdata.append(
    'redirect_uri',
    'https://stark-hollows-28667.herokuapp.com/instagram-connection',
  )
  formdata.append('code', authCode)

  // let config = {
  //   method: 'post',
  //   url:
  //     'https://api.instagram.com/oauth/access_token?client_id=863677095053453&client_secret=1ccac45713036043c2304b6248669ea4&grant_type=authorization_code',
  //   data: formdata,
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //   },
  // }

  // try {
  //   console.log('here3')
  //   // const { data } = await axios(config)
  //   const asd = await axios.get('http://localhost:5000')
  //   // const asd = await axios
  //   //   .post('https://api.instagram.com/oauth/access_token', {
  //   //     client_secret: '1ccac45713036043c2304b6248669ea4',
  //   //     client_id: 863677095053453,
  //   //     grant_type: 'authorization_code',
  //   //     redirect_uri:
  //   //       'https://stark-hollows-28667.herokuapp.com/instagram-connection',
  //   //     code:
  //   //       'AQCi1g5GbB_Ua1320zQ4tMbIvdOBeNC5_PC60Agol5POwdz6dLRFlthfIF2xGceqq45IZf2eDhWgVqPRqorC_xQv50j2v5WXk9hBgmBlRJwr-Uo8AZkURBH4Pjg0nCB82yR8jRZrbv6C6OlJN373ZjTZT-kgBEH_3B9iWnW675n6upS0Dgx_9q8zViaW3ZPKhM9FPDXfP0p2v7aXMVngSyhC-lKyLh35xtwpPHKE4VtS3w',
  //   //   })
  //   //   .then(function (response) {
  //   //     console.log('response is: ', response)
  //   //   })
  //   //   .catch(function (error) {
  //   //     console.log('err1: ', error)
  //   //   })
  //   console.log('data is: ', asd)
  // } catch (error) {
  //   console.log('err2: ', error)
  // }
}

export default function InstaConnect(props) {
  const [param, setParam] = useState('')
  // const [responseState, setResponseState] = useState('loading')
  const responseState = 'loading'

  useEffect(() => {
    setParam(new URLSearchParams(props.location.search).get('code'))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (param && param !== '') {
      console.log('Param: ', param)
      ;(async () => {
        sendReq(param.replace('#_', ''))
        // try {
        //   const { data } = await axios.get(
        //     "http://localhost:5000/api/insta-connect",
        //     {
        //       params: {
        //         code: param,
        //       },
        //     }
        //   );

        //   console.log(data);

        //   setResponseState("success");
        // } catch (error) {
        //   console.log(error.message);
        //   setResponseState("error");
        // }
      })()
    }
  }, [param])

  return (
    <>
      <AuthNavbar />
      <section id="login-main-section">
        <div className="login-content">
          <h3 className="login-header">Connect Your Instagram Account</h3>

          <div className="login-bundle">
            {responseState === 'loading' ? (
              <>Loading...</>
            ) : responseState === 'success' ? (
              <>
                Your Instagram Account is verified successfully. You can close
                this window and start to use Rosa. Welcome to Rosa!!
              </>
            ) : (
              <>
                An error is occured! Please try later or email to
                info@userosa.com
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}
