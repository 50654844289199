import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import AuthContext from './authContext';
import authReducer from './authReducer';
import setAuthToken from '../../utils/setAuthToken';

import io from 'socket.io-client';

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
  VERIFY_SUCCESS,
  VERIFY_FAIL,
  VERIFY_USERNAME_SUCCESS,
  VERIFY_USERNAME_FAIL,
  CLEAR_SUCCESS,
  SOCKET_CONNECTED,
  NEW_MESSAGE_FROM_SERVER,
  NEW_MESSAGE_FROM_MYSELF,
  CARD_INFO,
  READ_MESSAGE_NOW,
  EMPTY_NEW_MESSAGES,
  REGISTER_FALSEN,
} from '../types';

const AuthState = (props) => {
  const initialState = {
    user: null,
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    error: [],
    success: null,
    credits: null,
    verificationEmailSent: false,
    emailSentCount: 0,
    emailVerified: null,
    usernameVerified: false,
    socket: null,
    newMessage: [],
    cardInfo: {},
    flash: [],
    readMessageNow: false,
    registerSuccess: false,
    loginSuccess: false,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    window.addEventListener('orientationchange', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    return () => {
      registerFalsen();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state.isAuthenticated && state.user !== null) {
      const socket = io();
      dispatch({ type: SOCKET_CONNECTED, payload: socket });
      socket.on('hey', (e) => {
        socket.emit('hey-2', { socketid: e, userid: state.user._id });
      });
      socket.on('chat-message-from-server', (e) => {
        recievedMessageFromServer(e);
      });
      socket.on('signal-ready-to-be-read', (e) => {
        readMessageNow(e);
      });
    }
    if (!state.isAuthenticated && state.socket) {
      state.socket.disconnect();
    }
    // eslint-disable-next-line
  }, [state.isAuthenticated]);

  // useEffect(() => {
  //   if (state.isAuthenticated) {
  //     const socket = io()
  //     dispatch({ type: SOCKET_CONNECTED, payload: socket })
  //     socket.on('hey', e => {
  //       console.log('hey geldi: ', e)
  //       // socket.emit('hey-2', { socketid: e, userid: state.user._id })
  //     })
  //     socket.on('chat-message-from-server', e => {
  //       recievedMessageFromServer(e)
  //     })
  //     socket.on('signal-ready-to-be-read', e => {
  //       readMessageNow(e)
  //     })
  //   }
  //   // if (!state.isAuthenticated && state.socket) {
  //   //   state.socket.disconnect()
  //   // }
  //   //eslint-disable-next-line
  // }, [state.isAuthenticated])

  // RECIEVING A MESSAGE FROM SERVER
  const recievedMessageFromServer = (e) => {
    dispatch({ type: NEW_MESSAGE_FROM_SERVER, payload: e });
  };

  // Message from client himself
  const updateMessages = (e) => {
    dispatch({ type: NEW_MESSAGE_FROM_MYSELF, payload: e });
  };

  // Message ready to be read on DB
  const readMessageNow = (e) => {
    dispatch({ type: READ_MESSAGE_NOW, payload: e });
  };

  // Empty out new messages array after rendering
  const emptyNewMessages = () => {
    dispatch({ type: EMPTY_NEW_MESSAGES });
  };

  //LOAD USER
  const loadUser = async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      const res = await axios.get('/api/loaduser');
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

  //SIGN UP
  const signUp = async (formData) => {
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('api/signup', formData, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      let load;
      if (err.response.data.errors && err.response.data.errors.length) {
        load = err.response.data.errors[0].msg;
      } else {
        load = err.response.data.msg;
      }
      dispatch({
        type: REGISTER_FAIL,
        payload: load,
      });
    }
  };

  //LOGIN
  const login = async (formData) => {
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('api/login', formData, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      // loadUser()
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  //LOGOUT
  const logout = () => {
    dispatch({ type: LOGOUT });
    dispatch({
      type: CLEAR_ERRORS,
    });
    dispatch({ type: REGISTER_FALSEN });
  };

  //Register Falsen
  const registerFalsen = () => {
    dispatch({ type: REGISTER_FALSEN });
  };

  //Clear Errors
  const clearErrors = () => {
    dispatch({
      type: CLEAR_ERRORS,
    });
  };

  //Clear Success
  const clearSuccess = () => {
    dispatch({
      type: CLEAR_SUCCESS,
    });
  };

  //SEND EMAIL TO VERIFY EMAIL ADDRESS
  const sendEmail = async (formData) => {
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('api/email', formData, config);
      dispatch({
        type: EMAIL_SEND_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EMAIL_SEND_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  //SEND REQUEST TO VERIFY EMAIL
  const verifyEmail = async (token) => {
    try {
      const res = await axios.get(`/api/verify/email/${token}`);
      dispatch({
        type: VERIFY_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: VERIFY_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  //SEND REQUEST TO VERIFY USERNAME
  const verifyUsername = async (token) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      const res = await axios.get('api/verify/username');
      dispatch({
        type: VERIFY_USERNAME_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: VERIFY_USERNAME_FAIL,
        payload: err.response.data.errors,
      });
    }
  };

  //PROFILE PAGE CARD INFO
  const fetchCard = async (prop) => {
    const config = {
      header: {
        'Content-Type': 'application/json',
      },
    };
    const body = {
      stripeID: prop,
    };
    try {
      const res = await axios.post('/api/payment/card', body, config);
      dispatch({
        type: CARD_INFO,
        payload: res.data.card,
      });
    } catch (err) {
      console.log('Error is: ', err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        error: state.error,
        success: state.success,
        credits: state.credits,
        emailSentCount: state.emailSentCount,
        emailVerified: state.emailVerified,
        usernameVerified: state.usernameVerified,
        socket: state.socket,
        newMessage: state.newMessage,
        cardInfo: state.cardInfo,
        readMessageNow: state.readMessageNow,
        registerSuccess: state.registerSuccess,
        loginSuccess: state.loginSuccess,
        signUp,
        clearErrors,
        loadUser,
        login,
        logout,
        sendEmail,
        verifyEmail,
        verifyUsername,
        clearSuccess,
        recievedMessageFromServer,
        updateMessages,
        fetchCard,
        emptyNewMessages,
        registerFalsen,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
