import React, { useContext, useEffect } from 'react'
import DashboardLeft from '../layout/DashboardLeft'
import RightMenuHead from '../layout/RightMenuHead'
import RightMenuBodyCredits from '../layout/RightMenuBodyCredits'
import DashboardFooter from '../layout/DashboardFooter'
import Flash from '../layout/Flash'

import AuthContext from '../../context/auth/authContext'
import LikeContext from '../../context/like/likeContext'
import FlashContext from '../../context/flash/flashContext'

export default function Credits(props) {
  const authContext = useContext(AuthContext)
  const { loading, loadUser, clearErrors } = authContext

  const flashContext = useContext(FlashContext)
  const { flash, resetFlash } = flashContext

  const likeContext = useContext(LikeContext)
  const { getLikes } = likeContext

  useEffect(() => {
    loadUser()
    getLikes()
    window.scrollTo(0, 0)
    return () => {
      clearErrors()
      resetFlash()
    }
    //eslint-disable-next-line
  }, [])

  if (loading) {
    return <h1>Loading...</h1>
  }

  return (
    <div>
      <div className='dashboard-body'>
        {flash.length > 0 && <div className='flash-container-dashboard'>{flash && <Flash />}</div>}
        <div className='bg-left'></div>
        <div className='bg-right'></div>

        <div className='container'>
          {flash && <Flash />}
          <DashboardLeft pageName={'Credits'} />

          <div className='right-menu-credits'>
            <RightMenuHead title={'Credits'} />
            <RightMenuBodyCredits />
            <DashboardFooter pageName={'Credits'} />
          </div>
        </div>
      </div>
    </div>
  )
}
