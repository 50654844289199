import React, { useContext, useRef, useEffect } from 'react'
import LikeContext from '../../context/like/likeContext'

export default function LikeFilter() {
  const likeContext = useContext(LikeContext)
  const text = useRef('')

  const { filterLikes, clearLikeFilter, filteredLikes } = likeContext

  useEffect(() => {
    if (filteredLikes === null) {
      text.current.value = ''
    }
  })

  const onChange = e => {
    if (text.current.value !== '') {
      filterLikes(e.target.value)
    } else {
      clearLikeFilter()
    }
  }

  return (
    <form>
      <input ref={text} type='text' placeholder='Search Likes...' onChange={onChange} />
    </form>
  )
}
