import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Landing(props) {
  useEffect(() => {
    if (localStorage.token) {
      props.history.push('/home');
    }
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, []);

  return (
    <div className="flex">
      <nav id="navbar">
        <div className="header-logo-black">
          <img src="/logo-black.svg" alt="" />
        </div>
        {/* <Link className="login-button" to="login">
          Login
        </Link> */}
      </nav>
      <section id="hero-section">
        <div className="hero-section-a">
          <h1 className="hero-message">Like someone on Instagram?</h1>
          <p className="hero-text">
            Rosa is an Instagram dating app. First, you like your connections,
            and if they like you back… You know the drill. It’s a match!
          </p>
          {/* <div className="signup-bundle">
            <Link className="signup-button-primary" to="/signup-one">
              START FOR FREE*
            </Link>
            <p className="no-cards">*No credit card required</p>
          </div> */}
          <div
            onClick={() =>
              window.open(
                'https://apps.apple.com/us/app/rosaapp/id1667355346',
                '_blank',
                'noopener,noreferrer'
              )
            }
            className="signup-bundle"
          >
            <img src="/app-store-download.svg" alt="" />
          </div>
        </div>
        <div className="hero-section-b">
          <img src="/hero-image.png" alt="Rosa-Hero" />
        </div>
      </section>

      <section id="second-section" className="section">
        <div className="section-container">
          <div className="section-container-a">
            <h3 className="section-header section-header-second">
              Like your connections anonymously
            </h3>
            <p className="section-text section-text-second">
              Imagine liking your Instagram connections anonymously. Only if
              they like you back, your name is revealed, and you start
              messaging. Until then, nobody knows who you are!
            </p>
          </div>
          <i className="section-icon ig-icon fab fa-instagram"></i>
        </div>
      </section>

      <section id="third-section" className="section">
        <div className="section-container-reverse">
          <div className="section-container-a-reverse">
            <h3 className="section-header section-header-third">
              Enough of our time spent on useless swipes
            </h3>
            <p className="section-text section-text-third">
              Dating apps generally give you matches with complete strangers.
              Rosa is different. A basic Instagram connection makes a huge
              difference.
            </p>
          </div>
          <i className="section-icon far fa-clock"></i>
        </div>
      </section>

      <section id="fourth-section" className="section">
        <div className="section-container">
          <div className="section-container-a">
            <h3 className="section-header section-header-fourth">
              Using Rosa is simple and free for anyone
            </h3>
            <p className="section-text section-text-fourth">
              At Rosa 1 like costs 1 credit. It’s that simple. We give 5 credits
              free for everyone to start. If you want to purchase more credits,
              it starts at $2.99 per month.
            </p>
          </div>
          <i className="section-icon fas fa-gift"></i>
        </div>
      </section>

      <section className="section-small">
        <h3 className="section-header section-header-fifth">
          Start using Rosa
        </h3>
        <p className="section-text section-text-fifth">
          {/* It takes 3 minutes to start.
          <br /> */}
          5 credits free forever for everyone.
          <br />
          Cancel anytime.
        </p>
        {/* <Link className="signup-button-secondary" to="/signup-one">
          START MY FREE ACCOUNT
        </Link> */}
        <div
          onClick={() =>
            window.open(
              'https://apps.apple.com/us/app/rosaapp/id1667355346',
              '_blank',
              'noopener,noreferrer'
            )
          }
          className="signup-bundle"
        >
          <img src="/app-store-download.svg" alt="" />
        </div>
      </section>

      <section className="landing-footer">
        <div className="footer-a">
          <ul className="footer-ul">
            <li className="apple-eula">
              <a
                href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use (EULA)
              </a>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="support">Support</Link>
            </li>
          </ul>
        </div>
        <div className="footer-b">
          <div
            className="footer-logo"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <img src="logo-black.svg" alt="" />
          </div>
          {/* <p className="footer-kozmonot">
            Made with <span>❤️</span> by Kozmonot, Inc.
          </p> */}
        </div>
      </section>
    </div>
  );
}
