import React, { useContext, useState, useRef } from 'react'

import AuthContext from '../../context/auth/authContext'
import ModalContext from '../../context/modal/modalContext'

export default function RightMenuBodyEmail(props) {
  const authContext = useContext(AuthContext)
  const { user, sendEmail, emailSentCount } = authContext

  const modalContext = useContext(ModalContext)
  const { setModal } = modalContext

  const { isEmailVerified } = user

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  const { email, password } = formData

  const changeEmailInput = useRef(null)

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const changeEmailHandler = e => {
    e.preventDefault()
    if (email === '' || password === '') {
      console.log('Please fill in all fields', 'danger')
    } else {
      setModal({
        modalHeader: 'Oops! This feature is still in development :(',
        modalMessage:
          'In the meantime, you may send an email to info@userosa.com about changing your email address',
        modalButtonName: 'Got It!',
        modalFunction: 'changeEmail',
        modalCancelButton: false,
        modalFunctionButtonColor: 'green'
      })
    }
  }

  const sendAgainHandler = () => {
    if (emailSentCount < 3) {
      sendEmail({
        email: user.email,
        id: user._id
      })
    } else {
      alert('You reached email limit. Please check your email or contact support')
    }
  }

  return (
    <>
      <div className='right-menu-body-profile'>
        <div className='menu-body-container'>
          <div className='menu-body-cards-profile'>
            {isEmailVerified ? (
              <>
                <div className='plan-menu-card'>
                  <p className='card-header'>Change Email</p>
                  <form onSubmit={changeEmailHandler} className='form form-2' action='#'>
                    <p className='profile-menu-text'>
                      Enter your current password and a new email address
                    </p>
                    <input
                      ref={changeEmailInput}
                      className='input signup-password-2'
                      type='password'
                      placeholder='Current Password'
                      onChange={onChange}
                      name='password'
                    />
                    <input
                      className='input signup-email'
                      type='email'
                      placeholder='Email address'
                      onChange={onChange}
                      name='email'
                    />
                    <div className='button-bundle'>
                      <div onClick={changeEmailHandler} className='btn-blue email-button'>
                        CHANGE EMAIL
                      </div>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <>
                <div className='plan-menu-card-2'>
                  <p className='card-header-2'>Verify Your Email</p>
                  <p className='profile-menu-text-2'>
                    We've sent you an email. To continue, please check your email and verify your
                    address.
                  </p>
                  <div className='button-bundle'>
                    <div onClick={sendAgainHandler} className='btn-blue send-again-button'>
                      SEND AGIN
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
