import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import AuthContext from '../../context/auth/authContext'
import ModalContext from '../../context/modal/modalContext'

export default function RightMenuBodyPayment(props) {
  const authContext = useContext(AuthContext)
  const { cardInfo } = authContext

  const modalContext = useContext(ModalContext)
  const { setModal } = modalContext

  const cancelHandler = () => {
    setModal({
      modalHeader: 'Oops! This feature is still in development :(',
      modalMessage: 'Please send an email to info@userosa.com to delete your card.',
      modalButtonName: 'Got It!',
      modalFunction: 'deleteCard',
      modalCancelButton: false,
      modalFunctionButtonColor: 'green'
    })
  }

  return (
    <div class='right-menu-body-profile'>
      <div class='menu-body-container'>
        <div class='menu-body-cards-profile'>
          <div class='plan-menu-card'>
            <p class='card-header'>Payment Method</p>
            <div class='plan-mid-content'>
              <div class='current-plan-content'>
                <div class='current-plan-content-left'>
                  <span class='bold-content'>Card Type: </span>
                  {cardInfo.brand ? cardInfo.brand : ''}
                </div>
              </div>
              <div class='plan-renew-content'>
                <div class='plan-renew-content-left'>
                  <span class='bold-content'>Card Number: </span>
                  {cardInfo.last4 ? ' **** ' + cardInfo.last4 : ''}
                </div>
              </div>
              <div class='plan-renew-content'>
                <div class='plan-renew-content-left'>
                  <span class='bold-content'>Expiration Date: </span>
                  {cardInfo.exp_month ? cardInfo.exp_month + '/' + cardInfo.exp_year : ''}
                </div>
              </div>
            </div>

            <Link class='add-card-button' to='/credits'>
              ADD CARD
            </Link>

            <div class='logout-section'>
              <div onClick={cancelHandler} class='logout-button'>
                Delete Card
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
