import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import AuthContext from '../../context/auth/authContext'
import FlashContext from '../../context/flash/flashContext'

import AuthNavbar from '../layout/AuthNavbar'
import Flash from '../layout/Flash'

export default function SignUp(props) {
  const authContext = useContext(AuthContext)
  const { signUp, registerSuccess, error, clearErrors } = authContext

  const flashContext = useContext(FlashContext)
  const { flash, flashMessagesArray, setFlash, resetFlash } = flashContext

  const signUpEmailInput = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 950) {
      signUpEmailInput.current.blur()
    }
    if (typeof window !== 'undefined' && window.innerWidth > 950) {
      signUpEmailInput.current.focus()
    }
    if (sessionStorage.getItem('username')) {
      const sessionUsername = sessionStorage.getItem('username').toLowerCase()
      setUser({ ...user, username: sessionUsername })
    }
    //If the user manually hits this page, redirect them to the first page.
    if (!sessionStorage.getItem('username')) {
      props.history.push('/signup-one')
    }
    return () => {
      resetFlash()
      clearErrors()
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (error.length > 0) {
      setFlash({
        type: 'red',
        message: error[0],
        duration: 5000,
        id: uuidv4(),
      })
    }
    //eslint-disable-next-line
  }, [error])

  useEffect(() => {
    if (registerSuccess) {
      props.history.push('/home')
    }
  }, [registerSuccess, props.history])

  const [user, setUser] = useState({
    username: '',
    email: '',
    password: '',
  })

  const { username, email, password } = user

  const [loadingIconTrue, setLoadingIconTrue] = useState(false)

  useEffect(() => {
    if (flashMessagesArray.length > 0) {
      setLoadingIconTrue(false)
    }
  }, [flashMessagesArray])

  // This is very clever. Whichever the inputs change, it'll take the name attribute and match it with the input value
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setLoadingIconTrue(true)
    const validateEmail = (e) => {
      var regex = /\S+@\S+\.\S+/
      return !regex.test(e)
    }

    if (username === '' || email === '' || password === '') {
      setFlash({
        type: 'red',
        message: 'All fields must be filled.',
        duration: 5000,
        id: uuidv4(),
      })
    } else if (validateEmail(email.toLowerCase())) {
      setFlash({
        type: 'red',
        message: 'Please provide a valid email.',
        duration: 5000,
        id: uuidv4(),
      })
    } else {
      signUp({
        username,
        email: email.toLowerCase(),
        password,
        instagramUsername: username,
        instagramUsernameConnected: true
      })
    }
  }

  return (
    <>
      {flash && <Flash />}
      <AuthNavbar />
      <section id="signup-main-section-2">
        <div className="signup-content">
          <p className="signup-header">
            Step 2/2: Enter your email and password
          </p>
          <form onSubmit={onSubmit} className="login-form">
            <input
              ref={signUpEmailInput}
              autoFocus={true}
              onChange={onChange}
              className="input signup-username"
              name="email"
              type="text"
              placeholder="Email address"
              value={email}
              inputMode="email"
            />
            <input
              onChange={onChange}
              className="input signup-username"
              name="password"
              type="password"
              placeholder="Password"
              value={password}
            />
            <div className="signup-button-bundle">
              <Link className="btn-blue back-button" to="/signup-one">
                BACK
              </Link>
              <button
                onSubmit={onSubmit}
                className={
                  loadingIconTrue
                    ? 'btn success continue-button-disabled'
                    : 'btn success continue-button'
                }
              >
                {' '}
                {loadingIconTrue ? (
                  <i className="fas fa-spinner fa-spin spinner-2"></i>
                ) : (
                  ''
                )}
                {loadingIconTrue ? 'Please wait...' : 'CONTINUE'}{' '}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}
