import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import ChatroomContext from '../../context/chatroom/chatroomContext';
import AuthContext from '../../context/auth/authContext';

export default function ChatroomCard({ chatRoom }) {
  const { lastMessage, username, mongoID } = chatRoom;
  const { reciever } = useParams();

  const chatroomContext = useContext(ChatroomContext);
  const {
    setCurrentChatRoom,
    currentChatRoom,
    setLoadingTrue,
    clearMessages,
    currentChatroomID,
  } = chatroomContext;

  const authContext = useContext(AuthContext);
  const { user, loading } = authContext;

  const onClick = () => {
    if (lastMessage.chatroomID !== currentChatroomID) {
      if (currentChatRoom) {
        setCurrentChatRoom(chatRoom);
      }
      if (!currentChatRoom) {
        setLoadingTrue();
      }
      if (currentChatRoom && currentChatRoom.username !== username) {
        setLoadingTrue();
        clearMessages();
      }
    }
  };

  return (
    <>
      {chatRoom && !loading ? (
        <Link
          to={
            mongoID !== reciever ? '/messages/' + user._id + '-' + mongoID : '#'
          }
          onClick={onClick}
          className={
            (currentChatRoom !== null &&
              currentChatRoom &&
              currentChatRoom.username === username) ||
            reciever === mongoID
              ? 'chatroom-card-selected'
              : 'chatroom-card'
          }
        >
          <div className="chatroom-card-container">
            <div className="chatroom-card-flex-1">
              <div className="flex-left">
                <img
                  className="chatroom-profile-pic"
                  src={require('./icons-profile2.png')}
                  alt=""
                />

                <div
                  className={
                    lastMessage !== null
                      ? lastMessage.read || lastMessage.from === user._id
                        ? 'flex-left-info-column'
                        : 'flex-left-info-column-unread'
                      : 'flex-left-info-column'
                  }
                >
                  <div className="chatroom-username">@{username}</div>
                  {lastMessage !== null ? (
                    <div className="chatroom-last-message">
                      {' '}
                      {lastMessage && lastMessage.from === user._id
                        ? 'You: '
                        : username + ': '}{' '}
                      {lastMessage && lastMessage.text}
                    </div>
                  ) : null}
                </div>
              </div>

              {lastMessage &&
              !lastMessage.read &&
              lastMessage.to === user._id ? (
                <div className="chatroom-card-dot">·</div>
              ) : (
                ''
              )}
            </div>
          </div>
        </Link>
      ) : (
        ''
      )}
    </>
  );
}
