import _ from 'underscore';

export const generateRandomUserProfiles = (baseUsername, queryStatusCode) => {
  const usernames = [];

  for (let i = 1; i <= 10; i++) {
    const randomNumber = Math.floor(Math.random() * 1000); // Generate a random number
    const randomSuffix = randomNumber.toString().padStart(3, '0'); // Ensure 3 digits, e.g., 012

    // Apply different patterns
    const patterns = [
      `${baseUsername}${randomSuffix}`,
      `${baseUsername}_${randomSuffix}`,
      `${baseUsername}.${randomSuffix}`,
      `${baseUsername.substring(0, 2)}${randomSuffix}`,
      `${baseUsername}${i}`,
      `${baseUsername}_${i}`,
      `${baseUsername}.${i}`,
      `${baseUsername.substring(0, i)}_${baseUsername.substring(i)}`,
      `${baseUsername.substring(0, i)}.${baseUsername.substring(i)}`,
    ];

    const randomIndex = Math.floor(Math.random() * patterns.length);
    const randomUsername = patterns[randomIndex];
    usernames.push(randomUsername);
  }

  const fakes = _.shuffle(usernames).slice(0, 2);

  const trueProfile = {
    username: baseUsername,
    likedInstagramID: '',
    instagramFullName: '',
    instagramProfilePic: '',
    statusCode: queryStatusCode,
  };

  const fakeProfiles = fakes.map((v) => {
    return {
      username: v,
      likedInstagramID: '',
      instagramFullName: '',
      instagramProfilePic: '',
      statusCode: 200,
    };
  });

  return [trueProfile, ...fakeProfiles];
};
