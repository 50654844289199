import React, { useContext, useState, useRef, useEffect } from 'react'

// import AuthContext from '../../context/auth/authContext'
import ModalContext from '../../context/modal/modalContext'

export default function RightMenuBodyEmail(props) {
  // const authContext = useContext(AuthContext)
  // const { changePassword } = authContext

  const modalContext = useContext(ModalContext)
  const { setModal } = modalContext

  // const { changePassword } = user

  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    newPassword2: ''
  })

  const { currentPassword, newPassword, newPassword2 } = formData

  const changePasswordInput = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 950) {
      changePasswordInput.current.blur()
    }
    if (typeof window !== 'undefined' && window.innerWidth > 950) {
      changePasswordInput.current.focus()
    }
  }, [])

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const changePasswordHandler = e => {
    e.preventDefault()
    if (currentPassword === '' || newPassword === '' || newPassword2 === '') {
      console.log('Please fill in all fields', 'danger')
    } else {
      setModal({
        modalHeader: 'Oops! This feature is still in development :(',
        modalMessage:
          'In the meantime, you may send an email to info@userosa.com about changing your password',
        modalButtonName: 'Got It!',
        modalFunction: 'changePassword',
        modalCancelButton: false,
        modalFunctionButtonColor: 'green'
      })
    }
  }

  return (
    <>
      <div className='right-menu-body-profile'>
        <div className='menu-body-container'>
          <div className='menu-body-cards-profile'>
            <div className='plan-menu-card'>
              <p className='card-header'>Change Password</p>

              <form className='form form-2' action='#'>
                <p className='profile-menu-text'>Enter your current password and a new password</p>
                <input
                  ref={changePasswordInput}
                  className='input signup-password-2'
                  type='password'
                  placeholder='Current password'
                  name='currentPassword'
                  onChange={onChange}
                />
                <input
                  className='input signup-password-2'
                  type='password'
                  placeholder='New password'
                  name='newPassword'
                  onChange={onChange}
                />
                <input
                  className='input signup-password-2'
                  type='password'
                  placeholder='Repeat new password'
                  name='newPassword2'
                  onChange={onChange}
                />

                <div className='button-bundle'>
                  <div onClick={changePasswordHandler} className='btn-blue password-button'>
                    CHANGE PASSWORD
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
