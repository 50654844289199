import React, { useState, useContext, useEffect } from 'react';
import SearchItem from '../layout/SearchItem';

import SearchContext from '../../context/search/searchContext';
import LikeContext from '../../context/like/likeContext';

export default function Search({ placeholder }) {
  const searchContext = useContext(SearchContext);
  const {
    searchUser,
    clearSearch,
    searchResults,
    instagramUserNotFound,
    igUserNotFound,
  } = searchContext;

  const likeContext = useContext(LikeContext);
  const { searchFocused } = likeContext;

  const [searchItem, setSearchItem] = useState({ searchItem: '' });
  const [spinner, setSpinner] = useState(null);

  //bunu sadece search-results-container render olduğunda yapacağız yoksa her yerde esc i dinler
  useEffect(() => {
    document.addEventListener('keyup', keypressHandler1);
    return () => {
      document.removeEventListener('keyup', keypressHandler1);
    };
    //eslint-disable-next-line
  }, []);

  const keypressHandler1 = (e) => {
    if (e.keyCode === 27) {
      removeSearch();
    }
  };

  const keypressHandler2 = (e) => {
    if (e.keyCode === 13) {
      search();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keypressHandler2);
    if (searchItem.searchItem === '') {
      setSpinner(null);
    }
    return () => {
      document.removeEventListener('keydown', keypressHandler2);
    };
    //eslint-disable-next-line
  }, [searchItem]);

  useEffect(() => {
    if (searchResults.length === 0 && instagramUserNotFound === false) {
      setSpinner(null);
      setSearchItem({ searchItem: '' });
    } else {
      setSpinner(false);
    }
    // eslint-disable-next-line
  }, [searchResults]);

  useEffect(() => {
    if (searchResults.length === 0) {
      setSpinner(null);
      setSearchItem({ searchItem: '' });
    } else {
      setSpinner(false);
    }
    //eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    // setSpinner(true)
    setSearchItem({ ...searchItem, searchItem: e.target.value });
    igUserNotFound(false);
  };

  const search = () => {
    if (searchItem.searchItem !== '') {
      setSpinner(true);
      searchUser(searchItem);
    }
  };

  const searchButtonClickHandler = () => {
    if (searchResults.length === 0) {
      search();
    }
    if (
      searchResults[0] &&
      searchResults[0].username !== searchItem.searchItem
    ) {
      search();
    }
  };

  const removeSearch = () => {
    setSearchItem({ searchItem: '' });
    clearSearch();
  };

  return (
    <div className="right-menu-search">
      <div className="menu-search-container">
        <div className="input-container">
          <input
            className="dashboard-search-area"
            type="text"
            placeholder="Search for an Instagram username"
            onChange={onChange}
            name="searchItem"
            value={searchItem.searchItem}
            autoFocus={searchFocused ? true : false}
            autoComplete="off"
          />
          {spinner ? <i className="fas fa-spinner fa-spin spinner"></i> : ''}
          {spinner === false ? (
            <i onClick={removeSearch} className="fas fa-times close-icon"></i>
          ) : (
            ''
          )}
        </div>
        <div onClick={searchButtonClickHandler} className="search-container">
          <i className="fa fa-search search-icon" aria-hidden="true"></i>
        </div>
        {searchResults.length && !instagramUserNotFound ? (
          <div className="search-results-container">
            {searchResults.map((item) => (
              <SearchItem profile={item} />
            ))}
          </div>
        ) : null}
      </div>
      {!searchResults.length && instagramUserNotFound ? (
        <div className="search-results-container">
          <div className="user-not-found-card">
            User not found. Please try another username.
          </div>
        </div>
      ) : null}
    </div>
  );
}
