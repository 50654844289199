import React, { useContext, useEffect } from 'react'
import DashboardLeft from '../layout/DashboardLeft'
import RightMenuHead from '../layout/RightMenuHead'
import RightMenuBodyProfile from '../layout/RightMenuBodyProfile'
import DashboardFooter from '../layout/DashboardFooter'

import AuthContext from '../../context/auth/authContext'
import LikeContext from '../../context/like/likeContext'

export default function Credits(props) {
  const authContext = useContext(AuthContext)
  const { loading, loadUser } = authContext

  const likeContext = useContext(LikeContext)
  const { getLikes } = likeContext

  useEffect(() => {
    if (localStorage.token) {
      loadUser()
      getLikes()
    }
    //eslint-disable-next-line
  }, [])

  if (loading) {
    return <h1>Loading...</h1>
  }

  // if (!isAuthenticated) {
  //   return <Login />
  // }

  return (
    <div>
      <div className='dashboard-body'>
        <div className='bg-left'></div>
        <div className='bg-right'></div>

        <div className='container'>
          <DashboardLeft pageName={'Profile'} />
          <div className='right-menu'>
            <RightMenuHead title={'Profile'} />
            <RightMenuBodyProfile />
            <DashboardFooter pageName={'Profile'} />
          </div>
        </div>
      </div>
    </div>
  )
}
