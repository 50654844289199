import {
  CHATROOMS_LOADED,
  CHATROOMS_ERROR,
  SET_CURRENT_CHATROOM,
  CLEAR_CURRENT_CHATROOM,
  MESSAGES_LOADED,
  MESSAGES_ERROR,
  CLEAR_CHATROOMS,
  CLEAR_MESSAGES,
  SET_LOADING_TRUE,
  UPDATE_LAST_MESSAGE,
  CHATROOMID_LOADED,
  READ_MESSAGES,
  SEND_MESSAGE_SUCCESS
} from '../types'

export default (state, action) => {
  switch (action.type) {
    case CHATROOMS_LOADED:
      console.log('ChatRooms loaded')
      return {
        ...state,
        loading: false,
        chatRooms: action.payload.chatRooms
      }
    case CLEAR_CHATROOMS:
      return {
        ...state,
        chatRooms: []
      }
    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: null
      }
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true
      }
    case CHATROOMS_ERROR:
      return {
        ...state,
        chatrooms: [],
        loading: false,
        error: action.payload
      }
    case MESSAGES_LOADED:
      return {
        ...state,
        loading: false,
        messages: action.payload.messages
      }
    case MESSAGES_ERROR:
      return {
        ...state,
        messages: null,
        loading: false,
        error: action.payload
      }
    case SET_CURRENT_CHATROOM:
      return {
        ...state,
        currentChatRoom: action.payload
      }
    case CLEAR_CURRENT_CHATROOM:
      return {
        ...state,
        currentChatRoom: null,
        currentChatroomID: null
      }
    case CHATROOMID_LOADED:
      return {
        ...state,
        currentChatroomID: action.payload
      }
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageSent: true
      }
    case READ_MESSAGES:
      if (state.chatRooms) {
        const index = state.chatRooms.findIndex(e => e.lastMessage.chatroomID === action.payload)
        const newArray = [...state.chatRooms]
        if (index >= 0) {
          newArray[index].lastMessage.read = true
        }
        return {
          ...state,
          chatRooms: newArray
        }
      }
      /* falls through */
    case UPDATE_LAST_MESSAGE:
      if (state.chatRooms) {
        const index2 = state.chatRooms.findIndex(
          e => e.lastMessage.chatroomID === action.payload.id
        )
        const newArray2 = [...state.chatRooms]
        if (index2 >= 0) {
          newArray2[index2].lastMessage.date = action.payload.date
          newArray2[index2].lastMessage.from = action.payload.from
          newArray2[index2].lastMessage.to = action.payload.to
          newArray2[index2].lastMessage.read = action.payload.read
          newArray2[index2].lastMessage.text = action.payload.text
        }
        return {
          ...state,
          chatRooms: newArray2
        }
      }
    //  else {
    //   return {
    //     ...state,
    //     chatRooms: [
    //       {
    //         username: state.currentChatRoom.likedUsername,
    //         mongoID: state.currentChatRoom.likedMongoID,
    //         profilePic: state.currentChatRoom.likedInstagramProfilePic,
    //         lastMessage: {
    //           date: action.payload.date,
    //           from: action.payload.from,
    //           to: action.payload.to,
    //           text: action.payload.text
    //         }
    //       }
    //     ]
    //   }
    // }
    /* falls through */
    default:
      return state
  }
}
