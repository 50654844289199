import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import ChatroomContext from '../../context/chatroom/chatroomContext'
import AuthContext from '../../context/auth/authContext'

export default function MatchCard({ like }) {
  const chatroomContext = useContext(ChatroomContext)
  const {
    setCurrentChatRoom,
    getUserID,
    getChatrooms,
    setLoadingTrue,
  } = chatroomContext

  const authContext = useContext(AuthContext)
  const { user } = authContext

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const {
    likedUsername,
    matchDate,
    likedMongoID,
  } = like
  const date = new Date(matchDate)
  const month = date.getMonth()
  const monthName = monthNames[month].slice(0, 3)
  const dt = date.getDate()

  useEffect(() => {
    const func = async () => {
      await getUserID(likedUsername)
    }
    func()
    getChatrooms()
    //eslint-disable-next-line
  }, [])

  const onClick = () => {
    // if (chatRooms && chatRooms[0]) {
    //   setCurrentChatRoom()
    // }
    setLoadingTrue()
    setCurrentChatRoom(like)
    // createConnection()
  }

  return (
    <div className="body-match-card">
      <div className="match-card-container">
        <div className="match-card-pic">
          <img alt="" src={require('./icons-profile2.png')} />
        </div>
        <div className="match-info">
          <div className="match-username">@{likedUsername}</div>
          <div className="match-date">
            Matched at {dt} {monthName}
          </div>
        </div>
        <Link
          onClick={onClick}
          to={`messages/${user._id}-${likedMongoID}`}
          className="send-message-button"
        >
          Message
        </Link>
      </div>
    </div>
  )
}
