//These are the reducers

import {
  ADD_LIKE,
  DELETE_LIKE,
  GET_LIKES,
  GET_PLANS,
  FILTER_LIKES,
  CLEAR_LIKE_FILTER,
  CLEAR_LIKES,
  LIKE_ERROR,
  SEARCH_FOCUSED,
  NEW_MATCH,
  NEW_MATCH_FALSE
} from '../types'

export default (state, action) => {
  switch (action.type) {
    case GET_LIKES:
      let asd = action.payload.reverse()
      return {
        ...state,
        likes: asd,
        loading: false
      }
    case GET_PLANS:
      return {
        ...state,
        plans: action.payload,
        loading: false
      }
    case ADD_LIKE:
      window.scrollTo(0, 0)
      return {
        ...state,
        likes: [action.payload, ...state.likes],
        loading: false
      }
    case NEW_MATCH:
      return {
        ...state,
        newMatch: true
      }
    case NEW_MATCH_FALSE:
      return {
        ...state,
        newMatch: false
      }
    case DELETE_LIKE:
      return {
        ...state,
        likes: state.likes.filter(like => like._id !== action.payload),
        loading: false
      }
    case CLEAR_LIKES:
      return {
        ...state,
        likes: null,
        filteredLikes: null,
        filteredMessages: null,
        error: null
      }
    case FILTER_LIKES:
      return {
        ...state,
        filteredLikes: state.likes.filter(likedOne => {
          const regex = new RegExp(`${action.payload}`, 'gi')
          return likedOne.likedUsername.match(regex) || likedOne.likedInstagramFullName.match(regex)
        })
      }
    case CLEAR_LIKE_FILTER:
      return {
        ...state,
        filteredLikes: null
      }
    case LIKE_ERROR:
      return {
        ...state,
        error: action.payload
      }
    case SEARCH_FOCUSED:
      return {
        ...state,
        searchFocused: true
      }

    // //messages diye bir state oluşturacağız daha sonra. state.messages.filter yapıyoruz
    // case FILTER_MESSAGES:
    //   return {
    //     ...state,
    //     filteredMessages: state.messages.filter(messageComponent => {
    //       const regex = new RegExp(`${action.payload}`, 'gi')
    //       return messageComponent.likedUsername.match(regex) || messageComponent.likedInstagramFullName.match(regex) || messageComponent.likedInstagramID.match(regex) || messageComponent.messagesArray.match(regex)
    //     })
    //   }
    // case CLEAR_MESSAGE_FILTER:
    //   return {
    //     ...state,
    //     filteredMessages: null
    //   }

    default:
      return state
  }
}
