import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import AuthContext from '../../context/auth/authContext';

export default function RightMenuBodyProfile(props) {
  const authContext = useContext(AuthContext);
  const { user, logout, fetchCard, cardInfo, isAuthenticated } = authContext;


  const {
    profilePic,
    username,
    email,
    plan,
    isEmailVerified,
    isCustomer,
    stripeID,
  } = user;

  const logoutHandler = async () => {
    // alert('sikerim ha')
    // await clearChatrooms()
    logout();
  };

  useEffect(() => {
    if (isCustomer) {
      fetchCard(stripeID);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      props.history.push('/login');
    }
    //eslint-disable-next-line
  }, [isAuthenticated, props.history]);

  return (
    <>
      <div className="right-menu-body-profile">
        <div className="menu-body-container">
          <div className="menu-body-cards-profile">
            <div className="profile-card">
              <img
                className="profile-pic-big"
                src={profilePic ? profilePic : require('./icons-profile2.png')}
                alt=""
              />
              {/* <p className='profile-name'>Baran Ozbek</p> */}
              <p className="username">@{username}</p>
              <div className="profile-mid-content">
                <Link to="/change-email" className="email-content">
                  <div className="email-content-left">
                    <span className="bold-content">Email: </span>
                    {email}
                    {isEmailVerified === false ? (
                      <span className="pending">Pending</span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="email-content-right">
                    <i className="far fa-arrow-alt-circle-right"></i>
                  </div>
                </Link>

                <Link to="/change-plan" className="plan-content">
                  <div className="plan-content-left">
                    <span className="bold-content">Plan: </span>
                    {plan}
                    {/* / {plan === 'Free' ? 5 : user.credits + likes.length} Credits */}
                  </div>
                  <div className="plan-content-right">
                    <i className="far fa-arrow-alt-circle-right"></i>
                  </div>
                </Link>
                {isCustomer && (
                  <Link to="/change-payment" className="plan-content">
                    <div className="plan-content-left">
                      <span className="bold-content">Payment Method: </span>
                      {cardInfo.brand
                        ? cardInfo.brand + ' **** ' + cardInfo.last4
                        : ''}
                      {/* {cardInfo.brand + ' ' + '**' + cardInfo.last4} */}
                    </div>
                    <div className="plan-content-right">
                      <i className="far fa-arrow-alt-circle-right"></i>
                    </div>
                  </Link>
                )}

                <Link to="/change-password" className="password-content">
                  <div className="password-content-left">
                    <span className="bold-content">Password:</span> *******
                  </div>
                  <div className="password-content-right">
                    <i className="far fa-arrow-alt-circle-right"></i>
                  </div>
                </Link>
              </div>
              <div className="logout-section">
                <Link
                  to="login"
                  onClick={logoutHandler}
                  className="logout-button"
                  href="#"
                >
                  Log out
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
