import React, { useReducer } from 'react'
import ModalContext from './modalContext'
import modalReducer from './modalReducer'

import { SET_MODAL, REMOVE_MODAL } from '../types'

const ModalState = props => {
  const initialState = {
    modal: false,
    modalHeader: null,
    modalMessage: null,
    modalButtonName: null,
    modalFunction: null,
    id: null,
    likedUsername: null,
    likedInstagramID: null,
    likedInstagramFullName: null,
    likedInstagramProfilePic: null,
    modalCancelButton: true,
    modalFunctionButtonColor: null
  }

  const [state, dispatch] = useReducer(modalReducer, initialState)

  //SET A MODAL
  const setModal = modalProps => {
    dispatch({ type: SET_MODAL, payload: modalProps })
  }

  //CLEAR MODAL
  const removeModal = () => {
    dispatch({ type: REMOVE_MODAL })
  }

  return (
    <ModalContext.Provider
      value={{
        modal: state.modal,
        modalHeader: state.modalHeader,
        modalMessage: state.modalMessage,
        modalButtonName: state.modalButtonName,
        modalFunction: state.modalFunction,
        id: state.id,
        likedUsername: state.likedUsername,
        likedInstagramID: state.likedInstagramID,
        likedInstagramFullName: state.likedInstagramFullName,
        likedInstagramProfilePic: state.likedInstagramProfilePic,
        modalCancelButton: state.modalCancelButton,
        modalFunctionButtonColor: state.modalFunctionButtonColor,
        setModal,
        removeModal
      }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalState
