import React from 'react'
import { Link } from 'react-router-dom'

export default function AuthNavbar() {
  return (
    <>
      <nav id='navbar'>
        <Link to='/' className='header-logo-blue'>
          <img src='logo-blue-dark.svg' alt='Rosa-Logo' />
        </Link>
      </nav>
    </>
  )
}
