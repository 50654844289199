import React, { useContext } from 'react';

import LikeContext from '../../context/like/likeContext';
import ModalContext from '../../context/modal/modalContext';
import AuthContext from '../../context/auth/authContext';

export default function SearchItem({ profile }) {
  const likeContext = useContext(LikeContext);
  const { likes } = likeContext;

  const modalContext = useContext(ModalContext);
  const { setModal } = modalContext;

  const authContext = useContext(AuthContext);
  const { user, credits } = authContext;

  const { plan } = user;

  //Looking inside likes array to see if the search result is already liked. If yes zxc is true.
  const asd = likes.map((like) => like.likedUsername);
  const zxc = asd.includes(profile.username);

  const addLikeHandler = () => {
    if (credits === 0) {
      setModal({
        modalHeader: 'Not enough credits :(',
        modalMessage: `You can upgrade your plan to like @${profile.username}`,
        modalButtonName: 'Upgrade My Plan',
        modalFunction: 'goToPlans',
        modalCancelButton: false,
        modalFunctionButtonColor: 'green',
      });
    } else {
      let message;
      if (plan === 'Premium') {
        message = `@${profile.username} will be added to your likes.`;
      } else {
        message = `@${profile.username} will be added to your likes. Current credits: ${credits}`;
      }
      setModal({
        modalHeader: `Add ${profile.username}?`,
        modalMessage: message,
        modalButtonName: 'Add',
        modalFunction: 'addLike',
        likedUsername: profile.username,
        likedInstagramID: profile.likedInstagramID,
        likedInstagramFullName: profile.instagramFullName,
        likedInstagramProfilePic: profile.likedInstagramProfilePic
          ? profile.likedInstagramProfilePic
          : require('./icons-profile2.png'),
        modalFunctionButtonColor: 'blue',
      });
    }
  };

  return (
    <>
      <div className="search-card">
        <div className="search-card-container">
          <div className="search-card-pic">
            <img
              src={
                profile.profilePic
                  ? profile.profilePic
                  : require('./icons-profile2.png')
              }
              alt=""
            />
          </div>
          <div className="search-card-info">
            <div className="search-username">{profile.username}</div>
            {profile.fullName !== '' ? (
              <div className="search-fullName">{profile.fullName}</div>
            ) : (
              ''
            )}
          </div>
          {zxc ? (
            <div className="add-like-button-blue">Added</div>
          ) : (
            <div onClick={addLikeHandler} className="add-like-button-green">
              Add
            </div>
          )}
        </div>
      </div>
    </>
  );
}
