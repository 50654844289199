import React, { useContext } from 'react'

import ModalContext from '../../context/modal/modalContext'

export default function LikeCard({ like }) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const { likedUsername, likeDate, _id } = like
  const date = new Date(likeDate)
  const month = date.getMonth()
  const monthName = monthNames[month].slice(0, 3)
  const dt = date.getDate()

  const modalContext = useContext(ModalContext)
  const { setModal } = modalContext

  const removeLikeHandler = () => {
    // deleteLike(_id)
    setModal({
      modalHeader: `Remove ${likedUsername}?`,
      modalMessage: `@${likedUsername} will be removed from your likes. You will not be able to see if they like you
      or not. If it's a match, connection will be removed permanently.`,
      modalButtonName: 'Remove',
      modalFunction: 'deleteLike',
      id: _id,
      modalCancelButton: true,
      modalFunctionButtonColor: 'red',
    })
  }

  return (
    <div className="body-like-card">
      <div className="like-card-container">
        <div className="like-card-pic">
          <img src={require('./icons-profile2.png')} alt="" />
        </div>
        <div className="like-info">
          <div className="like-username">@{likedUsername}</div>
          <div className="like-date">
            Liked at {dt} {monthName}
          </div>
        </div>
        <div onClick={removeLikeHandler} className="remove-button">
          Remove
        </div>
      </div>
    </div>
  )
}
